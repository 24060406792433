.search-faq-info {
  text-align: center;
  height: 300px;
  background-color: #0052cc;
  color: white;
  h1 {
    padding-top: 50px;
  }
}
.faq-info-details-content {
  margin-top: 20px;
  padding: 20px;

  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    font-size: 14px;
  }
}
.search-faq-details-header {
  width: 400px;
  margin: auto;
  ::placeholder {
    color: #000;
  }
  input {
    border: 2px solid #ffffff8f;
    width: 100%;
    height: 50px;
    padding: 8px 15px;
    border-radius: 8px;
    background: rgba(0, 82, 204, 1);
    color: #fff;
    font-size: 16px;
    &::placeholder {
      color: #fff;
      font-size: 16px;
    }
    &:focus {
      outline: none;
      color: #fff;
    }
  }
}
//===================
.faq-info-details-content {
  .nav-tabs {
    .nav-item {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }
      .nav-link {
        margin-bottom: unset;
        background: none;
        font-size: 16px;
        &.active {
          color: #495057;
          background-color: none;
          background: none;
          border-color: none;
          border: none;
        }
      }
    }

    .accordion-flush {
      .accordion-item {
        margin-bottom: 0;
        border-bottom: 1px solid #ececec;
        padding: 10px;
        background: none;
      }
    }
  }
  .accordion-flush {
    .accordion-item {
      background: none;
      border-bottom: 1px solid #ececec !important;
      padding: 0rem 0;
      border-radius: 0px !important;
      margin-bottom: 6px;
      .accordion-header {
        background: none;
        h4 {
          font-size: 16px;
        }
      }
      button.accordion-button {
        padding: 1rem 0;
        background: none !important;
      }
    }
  }
}
