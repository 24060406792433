/* .student-hero-inner-wrap {
  background: #fff;
  height: 630px;
  border-radius: 5px;
  padding: 0px 30px;
  padding-left: 80px;
} */

.student-hero-main-wrap {
  min-height: 100vh;
  padding: 40px 0;
  display: flex;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.student-hero-main-wrap > * {
  height: 100%;
}
/* .student-hero-main-wrap > * > * {
  margin-bottom: 80px;
} */
.student-hero-content > h4 {
  font-size: 64px;
  line-height: 80px;
  font-weight: 700;
  color: #1e266d;
  margin-bottom: 20px;
}
.student-hero-content .form-text {
  margin-top: 0.25rem;
  font-size: 18px;
  line-height: 28px;
  padding-right: 16%;
  display: block;
  color: #516986;
}
.student-hero-left-b-card {
  background: #ffffff;
  border: 1px solid #0d55c1;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  min-height: 210px;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  bottom: -125px;
}
/* .herp-bottom-item > * > * {
  position: relative;
  width: 25%;
} */

/* .herp-bottom-item .student-hero-left-b-card {
  width: 96%;
} */

.student-hero-left-b-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.student-hero-left-b-card img {
  height: 82px;
}

.student-hero-content > p {
  position: relative;
  padding-left: 35px;
  margin: 30px 0px;
}

.student-hero-content > p:after {
  position: absolute;
  width: 25px;
  height: 3px;
  content: "";
  background: #085ddd;
  left: 0;
  top: 44%;
}

.student-hero-left-b-inner-image-wrap {
  position: relative;
  top: 7px;
}
.student-hero-left-b-inner-image-wrap > img {
  position: absolute;
  left: 30%;
  top: -2px;
  height: 40px;
  opacity: 0.5;
  display: none;
}
.student-hero-left-b-inner-image-wrap h6 {
  position: relative;
  top: 10px;
  margin-bottom: 0;
  color: #0052cc;
  font-weight: 700;
}
.hero-right-image img {
  width: 100%;
}
