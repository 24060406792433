.student-courses-card img {
  width: 100%;
  height: 230px;
  background-color: #fff;
  border: none;
  outline: none;
}
.student-courses-review img {
  height: 25px;
  display: inline-block;
  width: 25px;
}

.student-courses-review {
  display: flex;
  margin-bottom: 15px;
}
p.student-courses-timeline.card-text img {
  width: 25px;
  margin-right: 10px;
  height: 25px;
}

p.student-courses-timeline.card-text {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.student-courses-card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #ddd;
  padding-top: 15px;
}

.student-courses-card-button p {
  margin-bottom: 0;
}
.student-courses-card {
  margin-bottom: 25px;
}

.student-courses-card > .card {
  border-radius: 10px;
  position: relative;
}
.bookmark-button-wrap img {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  right: 15px;
  top: 15px;
  border: 1px solid #0052cc;
  border-radius: 3px;
  padding: 4px;
}
.package-area-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.package-title-area-left-part h4 {
  margin-bottom: 0px;
}

.package-title-area-left-part p {
  margin-bottom: 0;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 7px;
  vertical-align: 0em;
  content: "\f107";
  border-top: 0em solid;
  border-right: 0em solid transparent;
  border-bottom: 0;
  border-left: 0em solid transparent;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.ieltspackage-main-wrap {
  padding: 200px 0;
  padding-bottom: 50px;
}

/* ================================= */
.gmat-package-main-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.course-single-part-right h3 {
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  color: #1e266d;
  margin-bottom: 30px !important;
}
.course-single-part-right ul {
  padding-left: 0;
  margin-bottom: 40px;
}
.course-single-part-right ul li {
  list-style: none;
  display: flex;
  grid-gap: 15px;
  gap: 15px !important;
  margin-bottom: 15px !important;
}
.course-single-part-right p {
  color: #516986;
  font-size: 14px !important;
}
.course-single-part-right > ul > li > p {
  margin-bottom: 0;
}
.course-single-part-right > ul > li > p > span {
  font-weight: 600;
  display: inline-block;
  /* padding-left: 15px; */
  color: #0052cc;
}

.course-single-part-right ul li img {
  display: block;
  // overflow: hidden;
  width: 16px !important;
}

/* ================================  */
.gmat-package-main-wrap.gre {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
}

.gmat-package-main-wrap.ielts {
  padding: 100px 0;
}
.ieltspackage-main-wrap-all-package {
  padding: 60px 0;
}
.student-courses-card-button > * > a {
  color: #fff;
  text-decoration: none;
}
.course-single-part-left img {
  width: 100%;
  position: relative;
  bottom: -15px;
}
.test-single-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 25px;
  }
}
.test-single-item h6 {
  font-weight: 600;
  font-size: 20px;
  color: #0052cc;
  margin-bottom: 0;
}

.course-single-part-right h4 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 45px;
  color: #1e266d;
  line-height: 52px;
}
button.btn.package-connectbutton {
  background: #0052cc;
}
button.btn.package-connectbutton.btn.btn-secondary {
  background: #0052cc;
  width: 130px;
  height: 44px;
  border: none;
}

.test-single-item span {
  width: 50px;
  height: 50px;
  background: #dceaff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.test-single-item span img {
  width: 24px;
}
.course-single-part-right p {
  color: #2475ee !important;

  font-weight: 600 !important;
}
.btn-primary {
  color: #fff;
  background-color: #0052cc !important;
  border-color: #0052cc !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #005eeb !important;
  border-color: #005eeb !important;
}
@media only screen and (max-width: 992px) {
  .gmat-package-main-wrap > * > .row {
    flex-direction: column-reverse;
  }
}

.gmat-package-main-wrap {
  @media only screen and (max-width: 992px) {
    .gmat-package-main-wrap {
      padding-top: 100px !important;
    }
    .course-single-part-right {
      text-align: left;
      margin-bottom: 60px;
      padding-left: 0px !important;
      .row {
        justify-content: center;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .course-single-part-right h4 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 30px !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .course-single-part-right h4 {
      font-size: 22px !important;
      line-height: 32px !important;
      margin-bottom: 24px !important;
    }
  }
}
