//=================================
.new-hero-info-inner-area {
  background: #fff;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  border-radius: 10px;
  margin-top: -70px;
  position: relative;
  @media (max-width: 767px) {
    height: auto;
  }
  @media (max-width: 991px) {
    margin-top: 0px;
    border-radius: 0 0px 16px 16px;
    padding: 0;
    flex-wrap: wrap;
  }
  .single-new-info-item {
    width: 32%;
    padding: 20px 30px;
    text-align: center;
    @media (max-width: 767px) {
      padding: 20px 10px;
    }
    h2 {
      font-size: 40px;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }
    @media (max-width: 767px) {
      width: 49%;
    }
  }
  .single-new-info-item-divider {
    width: 1px;
    height: 100%;
    background: rgba(217, 217, 217, 1);
  }
}
