.acoount-email-password-area button {
  width: 100%;
}
.student-login-main-wrap {
  min-height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0; */
}
.student-login-main-wrap.signup-page {
  height: 100vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .student-account-form-wrap {
    width: 600px;
    background: #fff;
    border-radius: 10px;
    padding: 50px;
    label {
      font-size: 13px;
      color: #495057;
    }
    .form-control {
      border: none !important;
      background: #f9f9fe;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 13px;
      color: #727d88;
      font-weight: 300;
      border: 1px solid #d6d6d6 !important;
    }
    .invalid-feedback {
      font-size: 11px;
      color: #dc3545;
    }
    button {
      width: 100%;
      display: block;
      margin-top: 5px;
      border-radius: 10px;
    }
  }
}
.student-account-form-wrap {
  width: 650px;
  /* min-height: 650px; */
  background: #fff;
  border-radius: 10px;
  padding: 2%;
}
.account-form-title {
  h6 {
    margin-bottom: 7px;
  }
  h2 {
    font-weight: 700;
  }
}

.account-form-title h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 14px;
}
.account-btn-all-page.btn.btn-primary {
  display: block;
  width: 100%;
}
.account-with-social-media-link {
  text-align: center;
  padding-top: 40px;
}
.account-with-social-media-link h5 {
  margin-bottom: 20px;
}
.account-with-social-media-single-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}
.account-with-social-media-single-link > a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1877f2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
a.account-with-social-media-google-plus {
  background: #ea4434;
}
.account-with-social-media-single-link > * > img {
  width: 30px;
  height: 30px;
}

.forgot-password {
  margin-top: 15px;
  margin-bottom: 15px;
}

.forgot-password > a {
  color: #74788d;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.new-account > p {
  color: #495057;
}
.new-account > p > a {
  font-weight: 600;
  text-decoration: none;
  color: #556ee6;
}
.student-account-form-wrap.reset-password-student {
  min-height: 400px;
  margin-top: 70px;
}
.student-login-main-wrap.pass-reset {
  align-items: baseline;
}
.password-show-hide-wrap-class {
  position: relative;
}
.password-show-hide-wrap-class span {
  position: absolute !important;
  top: 40px;
  right: 15px;
  z-index: 9999;
}
label.form-label {
  color: #212529;
  margin-top: 15px;
}
//========================================================
.account-profile-main-area {
  // position: fixed;
  z-index: 9999999;
  height: 100vh;
  width: 100%;
  background: #fff;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  // @media only screen and (max-width: 767px) {
  //   display: block;
  // }
  .account-main-left-part {
    width: 50%;
    background: #0052cc;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    img.shadow-icon-account-page {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      opacity: 0.09;
      height: 100%;
      @media only screen and (min-width: 992px) {
        width: 125%;
        opacity: 0.06;
      }
    }
    img.account-page-info-image {
      position: absolute;
      bottom: 15px;
      width: 80%;
    }
  }
  .account-main-right-submit-info {
    width: 50%;
    position: relative;

    .account-form-title-wrap h3 {
      font-weight: 700;
      font-size: 34px !important;
    }
    .student-account-form-wrap {
      width: 450px;
      /* min-height: 650px; */
      background: #fff;
      border-radius: 10px;
      padding: 2%;

      button {
        width: 100%;
        display: block;
        margin-top: 5px;
        border-radius: 10px;
        font-size: 14px;
      }
      .acoount-email-password-area {
        label {
          margin-top: 0;
          display: none;
        }
      }
    }
    .project-logo-name-right-top {
      position: absolute;
      right: 40px;
      top: 30px;
      img {
        height: 40px;
      }
    }
    .student-login-main-wrap {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .account-form-title h3 {
        font-weight: 700;
        font-size: 28px !important;
        line-height: 40px !important;
        margin-bottom: 16px !important;
      }
      .acoount-email-password-area-wrap {
        .forget-password-link-area-button {
          margin-bottom: 15px;
          a {
            text-decoration: none;
            color: #4e4b66;
            font-size: 11px !important;
          }
        }
      }

      .account-form-title {
        text-align: left;
      }
      .account-input-field-single {
        .form-control {
          font-size: 13px;
          font-weight: 400;
        }
        position: relative;
        label.form-label {
          display: none;
        }
        .form-control:focus {
          color: unset !important;
          border: 1px solid !important;
          border-color: #dee2e6 !important;
          box-shadow: none !important;
        }
        label.form-label {
          color: #212529;
          margin-top: 0px;
        }
        span.password-show-btn {
          position: absolute;
          top: 12px;
          right: 16px;
        }
      }

      .account-with-social-media-link-bx-area {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p {
          font-size: 12px !important;
        }
        .account-with-social-media-single-link-items-wrap {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 15px;
          a {
            border: 1px solid #d0d5dd;
            width: 100%;
            text-align: center;
            padding: 10px;
            color: #888 !important;
            font-size: 14px !important;
            display: flex;
            justify-content: center;
            grid-gap: 8px;
            gap: 8px;
            border-radius: 10px;
            text-decoration: none;
            background: none;
            &.bg-primary {
              background: none !important;
              border: 1px solid #d0d5dd !important;
              color: #0052cc !important;
            }
            i {
              font-size: 20px;
            }
            img {
              height: 18px;
            }
          }
        }
      }
    }
    .new-account-link-area-wrap {
      padding: 20px 0;
      text-align: center;
      padding-bottom: 10px;
    }
  }
}
.account-access-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  span {
    color: #fff;
  }

  * {
    font-size: 14px !important;
    color: #fff;
    transition: 0.3s;
  }
  img {
    position: absolute;
    padding-left: 9%;
    height: 18px;
  }
}
.account-form-submit-button {
  width: 100%;
  button.btn.btn-primary {
    width: 100%;
    border-radius: 10px;
    transition: 5s;
    &:hover {
      .account-access-submit-button {
        transition: 5s;
        img {
          padding-left: 95px;
        }
      }
    }
  }
}
.student-login-main-wrap .account-input-field-single {
  position: relative;
  span.password-show-btn {
    position: absolute;
    top: 12px;
    right: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .student-account-form-wrap {
    width: 350px;

    background: #fff;
    border-radius: 10px;
    padding: 2%;
  }
  .account-profile-main-area
    .account-main-left-part
    img.shadow-icon-account-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 160%;
    opacity: 0.09;
    height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .account-access-submit-button img {
    position: absolute;
    padding-left: 80px;
    height: 18px;
  }
}
@media only screen and (max-width: 770px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .account-form-title-wrap
    h3 {
    font-weight: 700;
    font-size: 24px !important;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .project-logo-name-right-top {
    position: absolute;
    right: 20px;
    top: 12px;
  }

  .account-profile-main-area
    .account-main-right-submit-info
    .project-logo-name-right-top
    img {
    height: 24px;
  }
}
@media (max-width: 767px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .project-logo-name-right-top {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #efefef;
    padding: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .account-form-title-wrap
    h3 {
    font-weight: 700;
    font-size: 24px !important;
  }
  .account-access-submit-button span {
    color: #fff;
    padding-right: 30px;
  }

  .mobile-bg-show-account {
    width: 100%;
    position: absolute;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: -30px;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .student-account-form-wrap {
    width: 380px !important;
    background: #fff;
    border-radius: 10px;
    padding: 20px 24px;
    border: 1px solid #e9ecef;
    position: relative;
    padding-bottom: 30px;
    margin: 30px 0;
  }
  .account-access-submit-button img {
    position: absolute;
    padding-left: 60px;
    height: 18px;
  }
  .student-login-main-wrap.signup-page {
    display: flex;
    width: 100% !important;
    margin: auto;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 576px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .account-form-title-wrap
    h3 {
    font-weight: 700;
    font-size: 24px !important;
  }
  .account-access-submit-button span {
    color: #fff;
    padding-right: 30px;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .project-logo-name-right-top {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #efefef;
    padding: 12px;
    img {
      height: 32px;
    }
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .student-account-form-wrap {
    width: 330px;
    background: #fff;
    border-radius: 10px;
    padding: 20px 24px;
    border: 1px solid #e9ecef;
    position: relative;
    padding-bottom: 30px;
  }
  .account-access-submit-button img {
    position: absolute;
    padding-left: 50px;
    height: 18px;
  }
  .student-login-main-wrap.signup-page .student-account-form-wrap {
    background: #fff;
    border-radius: 10px;
    margin: 10px 16px;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .student-login-main-wrap
    .account-form-title
    h3 {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 34px !important;
    margin-bottom: 16px !important;
  }
}
@media only screen and (max-width: 450px) {
  .account-profile-main-area
    .account-main-right-submit-info
    .account-form-title-wrap
    h3 {
    font-weight: 700;
    font-size: 24px !important;
  }
  .account-access-submit-button span {
    color: #fff;
    padding-right: 30px;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .student-account-form-wrap {
    width: 100% !important;
    background: #fff;
    border-radius: 10px;
    padding: 30px 24px;
    border: 1px solid #e9ecef;
    position: relative;
    padding-bottom: 40px;
  }
  .account-form-submit-button
    button.btn.btn-primary:hover
    .account-access-submit-button
    img {
    padding-left: 80px;
  }
  .account-profile-main-area
    .account-main-right-submit-info
    .student-login-main-wrap {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
//======================
.account-profile-main-area .account-main-right-submit-info {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  .account-with-social-media-link-bx-area {
    text-align: center;
    > p {
      font-weight: 600;
    }
  }
  .account-with-social-media-single-link-items-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;

    a {
      border: 1px solid #d0d5dd;
      width: 100%;
      text-align: center;
      padding: 10px;
      color: #888 !important;
      font-size: 16px !important;
      display: flex;
      justify-content: center;
      gap: 8px;
      border-radius: 10px;
      text-decoration: none;
      background: none;
      display: flex;
      align-items: center;
      margin-top: 16px;
      font-size: 14px !important;
      &.bg-primary {
        background: none !important;
        border: 1px solid #d0d5dd !important;
        color: #0052cc !important;
        i {
          font-size: 20px;
        }
      }
      img {
        height: 18px;
      }
    }
  }
}
.shadow-icon-account-page {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.09;
}

//===============================================
.account-form-submit-button {
  .custom-spiner-main-wrap .loader-11:after,
  .custom-spiner-main-wrap .loader-11:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #ffffff #ffffff;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }

  .custom-spiner-main-wrap .loader-11:before {
    width: 6px;
    height: 6px;
    border-color: #fff #fff transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
  .custom-spiner-main-wrap {
    line-height: 1;
    .loader-11 {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      border: 3px solid;
      border-color: #fff #fff transparent transparent;
      animation: rotation 1s linear infinite;
    }
  }
}
