.after-email-verified-inner-page {
  width: 540px;
  margin: auto;
}
.after-email-verified-inner-page {
  width: 624px;
  margin: 50px auto;
  background: #fff;
  padding: 60px 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
}
.after-email-verified-page {
  min-height: 80vh;
}
.email-verification-page-left {
  text-align: center;
}
