.footer-main-wrap {
  background: #1b283a;
  padding: 20px 0;
}
.footer-main-wrap p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
}

.footer-right {
  text-align: right;
}
.footer-right ul {
  margin-bottom: 0;
  padding-left: 0;
}
.footer-right ul li {
  display: inline-block;
}
.footer-right ul > li a {
  text-decoration: none;
  color: #ffffffa8;
  margin-left: 30px;
  font-size: 15px;
  font-weight: 400;
}
.ssl-commerce-image-wrap img {
  width: 100%;
}
.ssl-commerce-image-bg-wrap {
  background: #1b283a;
  padding: 40px 0;
}
span.ssl-commerce-image-devider {
  display: block;
  width: 100%;
  height: 1px;
  background: #2f3f55;
}
