.audio-player-top-wrap {
  width: 100%;
  height: 90px;
  background: #cfe2ff;
}

.question-main-header-listening-part {
  height: 60px;
  display: flex;
  align-items: center;
  background: #ffff;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 3;
  .listening-ques-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .listening-header-right {
      display: flex;
      gap: 20px;
      * {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
  }
}
.question-part-review-area-right {
  background: #211d4f;
  padding: 32px;
  position: sticky;
  top: 60px;
  .exam-timer-area-top {
    text-align: center;
    border-bottom: 2px solid #151239;
    padding-bottom: 10px;
    span {
      display: block;
      color: #fff;
    }
  }
}
.single-ques-review-right-part {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  padding-top: 20px;
  .single-ans {
    width: 11%;
  }
  span {
    width: 30px;
    height: 30px;
    background: #34c38f;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 13px;
    display: block;
  }
  * {
    width: 9%;
  }
}
.audio-player-ques {
  position: sticky;
  top: 60px;
}

.listening-ques-main-area-left-part {
  position: relative;
  min-height: 100vh;
  .listening-media-player-wrap {
    position: sticky;
    top: 60px;
    z-index: 2;
  }
  .question-part-left-area-listening {
    padding-right: 25px;
    padding-top: 15px;
    padding-left: 15px;
  }
}
.listening-header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.answer-pallet-header {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 10px;
  padding: 10px 0;
}
.question-status-bottom {
  display: flex;
  padding: 20px 0;
  justify-content: center;
  color: #fff;
  grid-gap: 20px;
  gap: 20px;
  border-bottom: 2px solid #151239;
  margin-bottom: 20px;
}
.ques-status-single {
  > span:first-child {
    display: block;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
    &.question-status-deactive {
      background: #f46a6a;
    }
    &.question-status-active {
      background: #34c38f;
    }
  }
}
.ques-status-single {
  display: flex;
  align-items: center;
  gap: 10px;
}
.question-review-submit-area-bottom {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
  }
}
.form-check.mb-3 {
  padding-left: 65px;
}
.checkbox-ques-serial-no > form > .mb-3 {
  margin-bottom: 5px !important;
}
.dashboard-course-info {
  a {
    color: unset;
    padding: 0;
    color: #74788d;
    font-size: 18px;
    font-weight: 500;
  }
  h6 {
    font-size: 18px;
    display: inline-block;
    margin-bottom: 10px;
  }
  h2 {
    color: #000;
  }
}
.ques-serial > * > table > * > tr > td:last-child {
  font-weight: normal;
}
.q-p-header > h4::after {
  display: none;
}
.passage-single-left-part > h4::after {
  display: none;
}

.question-part-left-area-listening {
  .ques-serial {
    padding-top: 40px;
    padding-bottom: 0;
  }
  .sub-title-header {
    margin: 30px 0;
    margin-top: 15px;
  }
}
.listening-exam-inner-wrap > .row > * {
  width: 74%;
}

.ques-section-main-wrapper {
  .ques-title {
    padding-top: 35px;
  }
}

.exam-ques-main-page-wrap {
  .listening-exam-inner-wrap {
    border: 1px solid transparent;
    // .ques-right-part-container {
    //   width: 80%;
    //   margin: auto;
    // }
  }
}
.listening-exam-inner-wrap {
  .ques-right-part-container {
    width: 78%;
    margin: auto;
  }
}
