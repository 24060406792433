//=============================\

.new-spacial-feature-main-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: rgba(0, 82, 204, 0.06);
    filter: blur(80px);
    border-radius: 936px;
    width: 100%;
    height: 100%;
  }
  .new-spacial-feature-single-item {
    background: #ffffff;
    border-radius: 10px;
    height: 100%;
    padding: 43px;
    position: relative;
    z-index: 9;
    min-height: 270px;
    &.main-feature-box {
      background: #0052cc;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      padding-left: 60px;
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      h4 {
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
        @media (max-width: 1100px) {
          font-size: 34px;
          font-weight: 600;
        }
        @media (max-width: 767px) {
          font-size: 24px;
          font-weight: 600;
        }
      }
      a {
        border: none;
        padding: 12px 24px;
        font-size: 16px;
        border-radius: 8px;
        display: inline-block;
        margin-top: 15px;
        background: #fff;
        cursor: pointer;
      }
    }
    img {
      height: 50px;
      display: inline-block;
      margin-bottom: 14px;
    }
    h6 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
      color: rgba(0, 82, 204, 1);
      @media (max-width: 767px) {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        color: rgb(0, 82, 204);
        line-height: 26px;
      }
    }

    p {
      font-size: 14px !important;
      margin-bottom: 20px;
      line-height: 24px;
      // width: 92%;
      @media (max-width: 767px) {
        font-size: 13px !important;
        line-height: 22px;
      }
    }
    // margin-bottom: 10px;
    .new-feature-bottom-button-area {
      text-align: right;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      button {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        svg {
          width: 16px;
        }
      }
    }
  }
}

//=========================
.speacial-feature-modal-title .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  border: 2px solid #808080;
  border-radius: 50%;
}
