//=============================
.new-popular-course-full-wrap {
  background: #e5eefa;
  padding: 70px 0;
  .popular-area-title {
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
    h2 {
      font-weight: 700;
      font-size: 40px;
      color: #1e266d;
      @media (max-width: 1100px) {
        font-size: 34px !important;
        line-height: 40px;
      }
      @media (max-width: 767px) {
        font-size: 30px !important;
        line-height: 40px;
      }
    }
  }
  //========================== single card scss start =================
  .single-new-popular-course {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    height: 100%;
    a {
      text-decoration: none;
      color: unset;
    }
    .single-new-popular-course-content {
      .single-new-popular-course-content-header {
        height: 200px;
        img {
          border-radius: 8px 8px 8px 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .single-new-popular-course-info-content-bottom {
        padding: 0 10px;
        padding-bottom: 10px;
      }
      .new-course-rating-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        .new-home-package-rating-area {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        span {
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: 1;
        }
        i {
          font-size: 18px;
          color: #f1b44c;
        }
      }
      .new-course-info-content-middle {
        min-height: 115px;
        padding: 10px 0;
        > h6 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .popular-course-info-feature {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }
        .single-course-feature {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            line-height: 1;
            display: inline-block;
            margin-bottom: 0px;
          }
        }
        .package-subject-name-list-item-single-item.facilitator-review {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .new-course-bottom-price-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        border-top: 1px solid #efefef;
        button {
          border: none;
          color: #fff;
          background: #0052cc;
          padding: 10px 24px;
          border-radius: 8px;
        }
        b {
          display: flex;
          align-items: center;
          gap: 15px;
          font-size: 18px;
          @media (max-width: 767px) {
            gap: 6px;
            font-size: 14px;
          }
          del {
            color: #0052cc;
          }
          span {
            font-size: 18px !important;
            @media (max-width: 767px) {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
  //========================== single card scss end =================
  .new-popular-all-course-button-bottom {
    text-align: right;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    > a {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: flex-end;
      border: none;
      background: none;
      padding: 0;
      color: #0052cc;
      font-weight: 700;
      font-size: 18px !important;
      border-bottom: 2px solid #0052cc;
      text-decoration: none;
      i {
        color: #0052cc;
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
}
