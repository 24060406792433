.new-home-page {
  background: #f8f8fb;
  .new-hero-area {
    height: calc(100vh - 60px);
    background: #0052cc;
    @media (max-width: 991px) {
      height: auto;
    }
    .new-inner-hero-inner-wrap {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media (max-width: 991px) {
        padding: 30px;
        padding-top: 50px;
        padding-bottom: 0;
      }
      @media (max-width: 767px) {
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 0;
      }
      .new-hero-left-content {
        h2 {
          color: #fff;
          font-size: 46px;
          font-weight: 600;
          line-height: 62px;
          @media (max-width: 1100px) {
            font-size: 36px;
            line-height: 48px;
          }
          @media (max-width: 991px) {
            font-size: 34px;
          }
          @media (max-width: 767px) {
            font-size: 30px;
            line-height: 42px;
          }
          @media (max-width: 576px) {
            font-size: 24px;
            line-height: 34px;
          }
          span {
            font-size: 50px !important;
            position: relative;
            display: inline-block;
            z-index: 9;
            @media (max-width: 1100px) {
              font-size: 40px !important;
            }
            @media (max-width: 991px) {
              font-size: 38px !important;
            }
            @media (max-width: 767px) {
              font-size: 34px !important;
            }
            @media (max-width: 576px) {
              font-size: 24px !important;
            }
            &::after {
              content: "";
              width: 100%;
              height: 24px;
              position: absolute;
              background: #f1b44c;
              bottom: 4px;
              left: 0;
              z-index: -9;
              @media (max-width: 576px) {
                height: 14px;
              }
            }
          }
        }
        p {
          color: #fff;
          font-size: 18px !important;
          font-weight: 400 !important;
          opacity: 0.8;
          @media (max-width: 767px) {
            font-size: 14px !important;
          }
        }
        .new-hero-footer-button {
          display: flex;
          align-items: center;
          gap: 30px;
          margin-top: 40px;
          flex-wrap: wrap;
          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
          a {
            background: #fff;
            padding: 12px 24px;
            font-size: 16px !important;
            border-radius: 8px;
            text-decoration: none;
          }
          button {
            border: none;
            display: flex;

            gap: 10px;
            background: none;
            align-items: center;
            padding: 0;
            span {
              color: #fff;
              font-size: 16px !important;
              text-decoration: underline;
              font-weight: 700;
              svg {
                height: 40px;
                width: 40px;
              }
            }
          }
        }
      }

      .new-hero-right-image-area {
        height: calc(100vh - 60px);
        display: flex;
        align-items: flex-end;
        @media (max-width: 991px) {
          height: auto;
        }
        img {
          width: 100%;
          padding: 40px;
          padding-bottom: 0;
          padding-right: 0;
          object-fit: contain;
          height: 100%;
          position: relative;
          bottom: -16px;
          @media (max-width: 767px) {
            padding: 0px;
            padding-bottom: 0;
            padding-right: 0;
            bottom: -10px;
            width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
}
