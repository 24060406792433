.student-main-navbar-wrap {
  background: white;
  height: 60px;
  z-index: 999;
  position: sticky;
  top: 0;
  // box-shadow: 0px 0px 20px 0px #ede9e9;
  // box-shadow: 0px 0px 10px 0px #efefef;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  border: none;
  padding: 10px;
  padding-top: 10px;
  left: unset;
  right: 0;
  top: 52px;
}

.student-main-navbar-wrap > * {
  height: 100%;
}
.navbar-link-border {
  width: 1px;
  height: 25px;
  background: #d6d6d6;
  align-items: center;
}

.student-main-navbar-wrap .ms-auto.navbar-nav > * {
  padding: 0 13px;
  padding-right: 13px;
}
.student-main-navbar-wrap {
  nav.navbar.navbar-expand.navbar-light {
    a {
      img {
        height: 50px;
      }
    }
  }
  a.nav-link.cart-navbar-button {
    img {
      height: 20px !important;
    }
  }
  a.navbar-login-btn.nav-link {
    background: #0052cc;
    color: #fff !important;
    border-radius: 5px;
    padding: 4px 20px !important;
  }
}
a.navbar-register-btn.nav-link {
  background: #e0edff;
  border-radius: 5px;
  padding: 4px 20px !important;
  margin-left: 20px;
}
.navbar-link-border {
  width: 1px;
  height: 25px;
  background: #d6d6d6;
  align-items: center;
  margin: 0px 10px;
}
.student-hero-inner-wrap > .row {
  height: 100%;
}
.navbar-link-border {
  padding: 0 0px !important;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.profile-view-btn.nav-item.dropdown {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.show-student-profile {
  position: relative;
  width: fit-content;
}
.profile-view-btn.nav-item.dropdown .dropdown-toggle:empty::after {
  margin-left: 0;
  position: absolute;
  right: -7px;
  top: 11%;
}
.profile-view-btn.nav-item.dropdown .dropdown-menu.show {
  top: 50px;
  left: unset;
  right: 0;
  width: 15rem;
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #d6d6d6;
}
.show-student-profile > span {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  @media only screen and (max-width: 576px) {
    span {
      display: none;
    }
  }
}
.profile-view-btn.nav-item.dropdown > a {
  height: 100%;
}
.show-student-profile > span > img {
  width: 30px;
}
.profile-view-btn.nav-item.show.dropdown > * > ul {
  padding-left: 0;
}
.profile-view-btn.nav-item.show.dropdown ul > li {
  list-style: none;

  a {
    padding: 10px 24px;
    padding-left: 12px;
    display: block;
  }
  &:hover {
    background: #0052cc;
    a {
      color: #fff !important;
      i {
        color: #fff !important;
      }
      span {
        color: #fff !important;
      }
      svg path {
        stroke: #fff;
      }
      &.log-out-btn {
        svg path {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
  }
}
li.dropdown-mobile-show-desktop-hide {
  display: none;
}
li.dropdown-mobile-show-desktop-hide:active {
  .dropdown {
    color: #fff;
    button {
      span {
        color: #fff !important;
      }
      svg path {
        stroke: #fff !important;
      }
    }
  }
}
.profile-view-btn.nav-item.show.dropdown ul > li {
  .nav-main-navbar-dropdown-menu-link-wrap a {
    padding-left: 0;
    &:hover {
      background: #f1f1f1;
    }
  }

  .show.dropdown {
    color: #eee;
    button.package-list-dropdown-for-mobile > {
      i {
        color: #ddd !important;
        svg path {
          stroke: #fff;
        }
      }
      span {
        color: #ddd !important;
      }
    }
  }

  * {
    text-decoration: none;
    color: #74788d;
    gap: 8px;

    svg {
      width: 13px;
      path {
        stroke: #74788d;
      }
    }
  }
  a.svg-nav-icon-dropdown {
    display: flex;
  }
}

.profile-view-btn.nav-item.show.dropdown ul > li > * > i {
  margin-right: 8px;
}
.profile-view-btn.nav-item.show.dropdown ul > li > * > img {
  margin-right: 8px;
}
li.dropdown-mobile-show-desktop-hide {
  button.dropdown-toggle {
    background: unset !important;
    border: none;
    padding: 0;
  }
  button.package-list-dropdown-for-mobile {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.nav-main-navbar-dropdown-menu-link-wrap {
  min-width: 220px;
  a.nav-link {
    display: flex;
    gap: 10px;
    border-bottom: 2px solid #aecfff;
    // margin-top: 10px;
  }
  a .ghdjk {
    display: flex;
    align-items: center;
    height: 40px;
    background: #dceaff;
    width: 40px;
    justify-content: center;
    border-radius: 5px;

    img {
      width: 22px;
      height: unset !important;
    }
  }
  a .gdhdjhb {
    width: 150px;
    &:hover {
      h6 {
        color: #0052cc;
      }
    }
    h6 {
      font-size: 13px;
      margin-bottom: 0;
      color: #253642;
      font-weight: 400;
    }
    span {
      font-size: 13px;
      line-height: 20px !important;
      display: block;
    }
  }
}
.nav-main-navbar-dropdown-menu-link-wrap a.nav-link:last-child {
  border-bottom: none;
}

.gmat-package-main-wrap {
  .course-single-part-right button:hover {
    background: #005eeb !important;
  }
}
button.btn.package-connectbutton:hover {
  background: #005eeb !important;
}
li.dropdown-mobile-show-desktop-hide > .dropdown .dropdown-menu {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  right: unset !important;
  width: unset !important;
  margin-top: 0px;
  margin-bottom: 0px !important;
  background: #f1f1f1;
  a.nav-link {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    border-bottom: none;
    padding-left: 8px !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ghdjk {
    background: none;
    img {
      filter: grayscale(1);
    }
  }
}
//===================================================
.drop-down-show-body-area {
  display: flex;
}
.drop-down-show-body-area {
  display: flex;
  align-items: center;
  gap: 5px;
  .mobile-area-show-package-link {
    display: none;
  }
}
// mobile menu

@media only screen and (max-width: 767px) {
  .student-main-navbar-wrap a.navbar-login-btn.nav-link,
  a.navbar-register-btn.nav-link {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .mobile_menu {
    width: max-content !important;
    .mobile-menu-single-item {
      img {
        width: 20px;
        height: 20px !important;
      }
      span {
        font-size: 13px !important;
        white-space: nowrap;
      }
    }
  }
  .mobile-menu-dropdown-area-show {
    position: relative;
    .dropdown-mobile-menu-button-area {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background: #000;
        transition: 0.3s;
      }
      &.show-mobile {
        span {
          position: absolute;
          &:first-child {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
          }
          &:last-child {
            transform: rotateX(-90deg);
          }
        }
      }
    }
    .drop-down-show-body-area {
      position: absolute;
      right: 0;
      top: 40px;
      background: #fff;
      width: 150px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      gap: 10px;
      transform: rotateX(90deg);
      transform-origin: top;
      transition: 0.3s;
      &.body-content-show {
        transform: rotateX(0deg);
        align-items: unset;
        border: 1px solid #efefef;
      }
      a {
        margin-left: 0 !important;
        &.mobile-area-show-package-link {
          display: block;
        }
      }
    }
  }
}
