.inner-circle-complate-profile {
  position: absolute;
  width: 110px;
  height: 110px;
  left: 0;
  top: 0;
  transform: rotate(120deg);
  .flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
  }
  .single-chart {
    width: 100%;
    justify-content: center;
  }
  .circular-chart {
    display: block;
  }
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.4;
  }
  .circle {
    fill: none;
    stroke-width: 1.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  .circular-chart.blue .circle {
    stroke: #0052cc;
  }
  .percentage {
    font-size: 18px !important;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 130px;
    transform: rotate(240deg);
    font-weight: 700;
    color: #0052cc;
  }
}
