.people-choose-wrap {
  padding: 130px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.single-choose-single-item {
  background: #fff;
  padding: 40px 20px;
  border-radius: 10px;
  position: relative;
}
// .single-choose-single-item:last-child::after {
//   opacity: 0.5;
// }
.single-choose-single-item:after {
  content: "+";
  position: absolute;
  right: -3.5vw;
  top: 35%;
  font-size: 50px;
  color: #ffffff;
}

.choose-item-icon-image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: auto;
  background: #dceaff;
  line-height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-choose-bg-color {
  width: 90%;
  height: 90%;
  background: #ffffff70;
  border-radius: 50%;
}
.inner-choose-bg-color img {
  width: 33px;
}
.why-choose-us-items > * {
  gap: 2vw;
  justify-content: space-between;
}

.why-choose-us-items > * > * {
  width: 22%;
}
.people-choose-title h3 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 60px;
  font-weight: 700;
}

.single-choose-single-item > h4 {
  margin-top: 30px;
  font-weight: 600;
  font-size: 18px;
  color: #1e266d;
  line-height: 1;
  margin-bottom: 0;
  line-height: 28px;
}
.single-choose-single-item.people-last-child:after {
  display: none;
}
.single-choose-single-item.people-equal-item:after {
  content: "=";
}
.single-choose-single-item > p {
  color: #74788d;
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  .why-choose-us-items > * > * {
    width: 100%;
    margin-bottom: 50px;
  }
  .single-choose-single-item:after {
    content: "+";
    position: absolute;
    top: unset;
    right: unset;
    bottom: -70px !important;
    font-size: 50px;
    color: #ffffff;
    left: 48% !important;
  }
  .students-review-main-wrap > * > .row {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    *:last-child {
      margin-bottom: 15px;
      justify-content: center;
    }
    .item.student-review-slider-item {
      > p {
        color: #74788d;
        padding-right: 0%;
      }
      > img {
        margin: auto;
        margin-bottom: 20px;
        margin-top: 40px;
        height: 25px;
        opacity: 0.7;
      }
    }
    .students-review-slider .slick-dots {
      bottom: 25px;
      width: 15%;
      right: 40%;
    }
  }
}
