.writing-passage-wrap-content {
  padding: 50px;
  .writing-passage-wrap-inner-content {
    h2 {
      font-size: 28px !important;
      font-weight: 700;
    }
    > p {
      margin-bottom: 10px;
    }
    p {
      h3 {
        font-size: 18px !important;
        line-height: 28px !important;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }

    > * {
      margin-bottom: 25px;
      > br {
        display: none;
      }
      > * {
        font-weight: 500;
      }
    }
  }
  &.right {
    h4 {
      margin-bottom: 25px;
      line-height: 38px;
      font-size: 26px;
      font-weight: 700;
    }
    p {
      margin-bottom: 25px;
    }
    textarea.form-control {
      margin-bottom: 15px;
    }
  }
}
.writing-passage-wrap-inner-content h2 {
  font-size: 22px !important;
}
.writing-passage-wrap-inner-content
  .writing-exam-file-upload-drag-drop-area
  > *
  > * {
  width: 100% !important;
  min-height: 110px;
  cursor: pointer;
  > div {
    justify-content: center !important;
    span {
      text-align: center;
      display: block;
    }
  }
}

.writing-exam-ques-image-single-wrap {
  width: 100%;
  height: 250px;

  position: relative;
  border-radius: 10px;
  .writing-exam-ques-image-upload-area {
    height: 250px;
    overflow-y: scroll;
    overflow-x: scroll;
    position: relative;
    img.upload-ques-image-item {
      width: 100%;
    }
  }
  button.close-image-button {
    position: absolute;
    top: 10px;
    z-index: 9999;
    right: 10px;
    background: #e10000 !important;
    border: #e10000 !important;
  }
}
.writing-ques-answer-content {
  p {
    span {
      cursor: pointer;
    }
  }
}
.writing-click-answer-info {
  color: blue;
}
[contenteditable] ~ grammarly-extension,
input ~ grammarly-extension,
textarea ~ grammarly-extension {
  display: none;
}
[contenteditable] ~ grammarly-extension,
input ~ grammarly-extension,
textarea ~ grammarly-extension {
  display: none;
}
