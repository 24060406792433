.card-active-deactive-content {
  position: relative;
  .active-pending-top-right-massage {
    position: absolute;
    z-index: 9;
    right: 20px;
    top: 20px;
    span.color-success-text {
      background: #dafff1;
      color: #34c38f;
      padding: 4px 12px;
      border-radius: 15px;
    }
  }
  .card-body > * > h4 {
    font-size: 20px;
    font-weight: 600;
  }
}
