.result-under-process-main-wrap {
  display: flex;
  align-items: center;
  height: 100vh;
  text-align: center;
  .result-under-process-title {
    text-align: left;
    margin-bottom: 15px;
  }
  .result-under-process-inner-wrap {
    background: #fff;
    padding: 80px;
    border-radius: 10px;
    img {
      display: inline-block;
      margin-bottom: 25px;
    }
    h3 {
      margin-bottom: 10px;
    }
  }
}
