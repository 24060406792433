//======================================

.get-ready-started-full-area {
  padding: 60px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  .get-ready-started-inner-area {
    background: #0052cc;
    padding: 40px;
    padding-bottom: 0;
    border-radius: 20px;
    @media (max-width: 991px) {
      padding-bottom: 0px;
      padding-right: 0;
      > .row {
        flex-direction: column-reverse;
      }
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
      padding: 0 15px;
      padding-top: 40px;
      > .row {
        flex-direction: column-reverse;
      }
    }

    .get-started-image-left-area {
      margin-top: -80px;
      text-align: center;
      img {
        width: 80%;
        &.desktop-get-stated-image {
          @media (max-width: 991px) {
            display: none;
          }
        }
        &.mobile-get-stated-image {
          display: none;
          @media (max-width: 991px) {
            display: block;
            max-height: 400px;
            width: unset;
            margin: auto;
          }
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
      @media (max-width: 991px) {
        margin-top: 50px;
      }
    }
    .get-started-content-right-area {
      @media (max-width: 767px) {
        text-align: center;
      }
      padding-bottom: 40px;
      h2 {
        color: #fff;
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
      p {
        font-size: 16px !important;
        color: #fff;
        line-height: 28px;
        margin-bottom: 0px;
        opacity: 0.75;
        @media (max-width: 767px) {
          font-size: 14px !important;
          margin-bottom: 8px;
        }
      }
      *.get-stated-link-button {
        background: #fff;
        display: inline-block;
        margin-top: 30px;
        padding: 14px 30px;
        border-radius: 8px;
        text-decoration: none;
        font-size: 16px !important;
        i {
          transform: rotate(135deg);
        }
      }
    }
  }
}

.new-home-page {
  .create-own-package-slider-area-wrap
    .create-package-single-slide
    .create-package-slider-left-part
    a {
    display: inline-block;
    margin-top: 34px;
    background: #0052cc;
    padding: 12px 30px;
    text-decoration: none;
    font-size: 16px !important;
    border-radius: 10px;
    color: #fff;
    /* height: 44px; */
  }
}
