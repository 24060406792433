//============================
.create-own-package-slider-area-wrap {
  min-height: 80vh;
  display: flex;
  align-items: center;
  padding: 50px 0;
  @media only screen and (max-width: 991px) {
    min-height: unset;
  }

  .create-package-single-slide {
    .create-package-slider-left-part {
      display: flex;
      align-items: center;
      height: 100%;
      //   @media only screen and (max-width: 680px) {
      //   }
      h4 {
        font-size: 40px;
        font-weight: 700;
        color: #1e266d;
        margin-bottom: 20px;
        line-height: 52px;
        // @media only screen and (max-width: 991px) {
        //   font-size: 34px !important;
        //   font-weight: 700;
        //   color: #1e266d;
        //   margin-bottom: 20px;
        //   line-height: 46px !important;
        // }
        @media only screen and (max-width: 767px) {
          font-size: 28px !important;
          line-height: 40px !important;
        }
        @media only screen and (max-width: 575px) {
          font-size: 22px !important;
          line-height: 32px !important;
          // br {
          //   display: none;
          // }
        }
      }
      p {
        font-size: 14px !important;
        line-height: 26px;
      }
      a {
        display: inline-block;
        margin-top: 34px;
        background: #0052cc;
        padding: 10px 30px;
        text-decoration: none;
        font-size: 16px !important;
        border-radius: 4px;
        color: #fff;
        height: 44px;
      }
    }
    .create-package-slider-right-part {
      text-align: right;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media only screen and (max-width: 767px) {
        text-align: center;
      }
      @media only screen and (max-width: 680px) {
        text-align: center;
        margin-top: 30px;
      }

      img {
        height: unset;
        width: 100%;
      }
    }
  }
}
