.link-not-found-404-page {
  min-height: 86vh;
  .empty-cart-main-area-wrap-empty {
    .empty-cart-inner-wrap {
      background: #fff;
      padding: 40px 20px;
      border-radius: 10px;
      box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
      h4 {
        margin-bottom: 7px !important;
        font-weight: 700;
      }
    }
  }
}
