//===================================
.student-create-package-main-area-wrap {
  padding-top: 70px;
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
  .student-create-title {
    margin-bottom: 40px;
    h3 {
      font-weight: 700;
      color: #1e266d;
    }
    p {
      font-size: 16px !important;
      @media only screen and (max-width: 767px) {
        font-size: 13px !important;
      }
    }
  }
  .create-right-banner-image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      text-align: center;
    }
    img {
      // height: 100%;
      position: relative;
      width: 100%;
      max-width: 410px;
      // top: -30px;
      @media only screen and (max-width: 991px) {
        top: 0px;
      }
      @media only screen and (max-width: 767px) {
        top: 20px;
        width: 100%;
        height: unset;
      }
    }
  }
}
