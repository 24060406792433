//=========================================
.student-hero-main-area-wrap {
  height: 90vh;
  width: 100%;
  overflow: hidden;
  * {
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    // min-height: 660px;
    height: auto;
    // margin-top: 24px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slick-dots {
    bottom: 24px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    height: unset;
    button {
      cursor: pointer;
      color: white;
    }
    li {
      button:before {
        opacity: 0.55;
        color: #fff;
        font-size: 12px;
      }
      &.slick-active button:before {
        opacity: 0.95;
        color: #fff;
      }
    }
  }
}

//==============================================
.student-hero-main-area-wrap {
  transition: 0.5s;
  @media only screen and (max-width: 1100px) {
    height: unset;
    > * {
      margin-top: 24px;
    }
    .slider-show-desktop {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  img {
    @media only screen and (max-width: 1100px) {
      width: 100%;
      height: unset;
      object-fit: unset;
    }
  }
}

.slider-show-tab {
  display: none;
}
.slider-show-mobile {
  display: none;
  @media only screen and (max-width: 575px) {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .slider-show-desktop {
    display: none;
  }
  .slider-show-tab {
    display: block !important;
    margin-left: 10px;
    margin-right: 10px;
    @media only screen and (max-width: 575px) {
      display: none !important;
    }
  }
}
