$themeBgColor: #e5e5e5;
$cardBorderColor: #ededf6;

// icon
$iconColorPrimary: #6f52ed;
$iconColorSecondary: #4cb8ff;
$NavIconColor: #c9ced6;
$paginationBtnBgColor: #919eab;
$settingIconColor: #969cba;
$iconBgColor: #713bdb0d;
$revenueIconBgColor: #ffb80012;

// text
$textColorPrimary: #a6acbe;
$textColorSecondary: #c9ced6;
$textColorBlack: #000000;

// border Color
$borderColor: #eef0f6;

// profile
$scheduleClassColor: #71717a;
$profileTextColor: #91919f;
$profileBtnBgColor: #373b53;
$profileRoleColor: #26235c;
$profileOuterShapeColor: #23d764;
$scheduleClassDateColor: #71717a;

// button
$actionBtnBgColor: #1f64ff;
$accordionCollapseColor: #aab2c8;
