@import "./variables";
@import "./_layout";

@import "./Custom/global";
@import "./Custom/Components/profileCard";
@import "./Custom/Pages/ProfileSettings";
@import "common.scss";

h3 {
  font-size: 40px !important;
  line-height: 52px !important;
  @media only screen and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  @media only screen and (max-width: 576px) {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  @media only screen and (max-width: 450px) {
    font-size: 22px !important;
    line-height: 34px !important;
  }
}
