.react-responsive-modal-modal.ratingModal {
  width: 100%;
  padding: 0;
  .rating-header {
    text-align: center;
    padding: 14px;
    background: #d6e7ff;
  }
  .rating-exam-main-body {
    padding: 30px 80px;
    .rating-stars {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      span {
        font-size: 30px !important;
        i {
          color: #f1b44c;
        }
      }
    }

    .rating-comment-box {
      textarea.form-control {
        min-height: calc(1.5em + 0.75rem + 2px);
        border: 1px solid #ababab !important;
      }
    }
    .rating-bottom-wrap {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 30px;
      button {
        &:first-child {
          border: none;
          background: #d6e7ff;
          color: #0052cc;
        }
        &:last-child {
          border: none;
          background: #0052cc;
          color: #fff;
        }
      }
    }
  }
}
