.complate-ques-table-single-data {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #bcd2e7;
    border-radius: 50%;
    font-weight: 700;
  }
}
input.form-control.ques-sentences {
  border: none !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
  background: transparent;
}

.number {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bcd2e7;
  border-radius: 50%;
  font-weight: 700;
}
