.djtq-text-primary {
  color: $iconColorPrimary;
}
.djtq-text-secondary {
  color: $iconColorSecondary;
}
.text-content {
  color: $textColorPrimary;
  font-weight: 600;
}

.borderBottom {
  border-bottom: 1px solid $borderColor;
}

.cursor-pointer {
  cursor: pointer;
}

.border-green {
  border-color: $profileOuterShapeColor !important;
  border-left-color: transparent !important;
}

.border-blue {
  border-color: $actionBtnBgColor !important;
  border-left-color: transparent !important;
}

.profileTextColor {
  color: $profileTextColor;
}

.scheduleClassColor {
  color: $scheduleClassColor;
}

.fileUpload {
  color: transparent;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
}

.questionPallet {
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.button {
  &:focus {
    box-shadow: none;
  }
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent !important;
  border-radius: 50%;
}
::-webkit-scrollbar-thumb:vertical {
  background: #7c7c7cfb !important;
  border-radius: 20px;
  // transition: all 650ms ease-in !important;
}
::-webkit-scrollbar-track:vertical:hover {
  background: #dfdfdfde !important;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #7c7c7cfb !important;
  border-radius: 20px;
  // transition: all 650ms ease-in !important;
}
::-webkit-scrollbar-track:horizontal:hover {
  background: #dfdfdfde !important;
  border-radius: 20px;
}
