.card-title.h5 {
  font-weight: 700;
}

.package-title-area-right-part-my-choose-package.nav-item.dropdown {
  position: relative;
}

.package-title-area-right-part-my-choose-package.nav-item.dropdown
  .dropdown-menu.show {
  left: unset !important;
  right: 0 !important;
  position: absolute !important;
  top: 20px !important;
}

.package-title-area-right-part-my-choose-package.nav-item.dropdown > a:after {
  display: none;
}

.dashboard-main-wrap.my-course-page {
  padding: 50px 0px;
}

.package-all-content-details-sub-title-time {
  display: flex;
  justify-content: left;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 4px;
}
.package-all-content-details-title > h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #253642;
}
.feedback-review-count > h3 {
  font-size: 22px;
  margin-bottom: 0px;
}
.package-all-content-details-sub-title-time p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 3px;
  color: #77838f;
}

.package-all-content-details-sub-title-time > p > span {
  line-height: 1;
  font-size: 16px;
  color: #0052cc;
}

.PackageDetails-main-body {
  padding: 70px 0;
}

.PackageDetails-main-body {
  padding: 70px 0;
}
.package-details-content > h4 {
  font-weight: 600;
  font-size: 24px;
}

.package-details-content > p {
  color: #74788d;
}
.package-details-content > p {
  color: #74788d;
}

table.table.table-borderless > * > * {
  font-weight: 700;
}

.package-all-content-details table.table.table-borderless th {
  font-weight: 400;
  color: #0052cc;
}
.package-all-content-details table.table.table-borderless tr th {
}

.package-all-content-details table.table.table-borderless tr * span {
  display: block;
  width: 110px;
}
.package-details-table {
  padding: 25px 20px;
  margin: 30px 0;
  background: #e6f0fe;
  border-radius: 10px;
}

.accordion-flush .accordion-item {
  margin-bottom: 15px;
  border-bottom: none;
  border-radius: 10px !important;
}

button.accordion-button {
  background: #fff !important;
  box-shadow: 0px 0px 0px #fff !important;
}

.feedback-review-bar-wrap {
  display: flex;
  width: 100%;
  justify-content: left;
  margin-bottom: 10px;
}

.feedback-review-bar-wrap > .progress {
  width: 75%;
}

.feedback-review-bar-wrap > span:first-child {
  width: 40px;
}

.feedback-review-bar-wrap > span:last-child {
  width: 40px;
  margin-left: 10px;
}

.feedback-single-item {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border-bottom: 1px solid #cecece;
  padding: 20px 0;
}

.feedback-single-item:last-child {
  border-bottom: 1px solid transparent;
}

.feedback-single-item-left {
  width: 20%;
}

.feedback-single-item-right {
  width: 80%;
}

.feedback-review-count {
  text-align: center;
}

.feedback-review-count i {
  color: #f49e1c;
}

.feedback-review-count h2 {
  font-size: 42px;
  font-weight: 600;
}

.feedback-main-wrap {
  border: 1px solid #cecece;
  /* padding: 30px 0px; */
  border-radius: 10px;
}
.feedback-main-wrap.all-comments {
  border: 1px solid transparent;
}
.feedback-single-item-left.feedback-content-left {
  width: 100px !important;
  text-align: center;
}
.feedback-single-item-left.feedback-content-left img {
  height: 50px;
}
.feedback-single-item-right.feedback-content-right {
  width: 90%;
}

.feedback-single-item-right.feedback-content-right > * {
  text-align: left;
}

.feedback-review-bar-wrap > span > i {
  color: #f49e1c;
  margin-right: 3px;
}
.feedback-single-item.feedback-review-see-more-feedback {
  justify-content: center;
}
.feedback-main-wrap.all-comments > *:nth-child(even) {
  margin-left: 100px;
}
.feedback-main-wrap.all-comments > *:last-child {
  border-bottom: 1px solid #cecece;
}

.comment-account-area {
  text-align: center;
  padding: 20px 30px;
  margin: 50px 0;
  background: #e0edff;
  border-radius: 5px;
}

.comment-account-area a {
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.package-details-content {
  margin-bottom: 40px;
}
.package-details-feedback-area > * > h3 {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}
.product-content-list-items-wrap ul {
  padding-left: 0;
  margin-bottom: 0;
}

.product-content-list-items-wrap ul li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  margin-bottom: 6px;
  font-size: 13px;
}

.product-content-list-items-wrap ul > li > span > i {
  font-size: 24px;
  color: #0052cc;
}

.product-content-list-items-wrap ul > li > span {
  line-height: 1;
}
.sidebar-single-product {
  padding: 30px;
  background: #e6f0fe;
  border-radius: 10px;
}

.product-image {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.product-image img {
  width: 100%;
  height: 100%;
}
.product-content h3 {
  border-bottom: 1px solid #d3e5fd;
  padding-bottom: 25px;
  margin-bottom: 25px;
  font-size: 20px;
  font-size: 20px;
  font-weight: 600;
}
.product-content > h4 {
  font-size: 16px;
}

.product-content-sidebar-btn-wrap {
  margin-top: 30px;
}
button.btn.add-to-cart-btn {
  background: #d9e6fb;
  margin-left: 15px;
  padding: 8px 20px;
}

.product-content-sidebar-btn-wrap {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.product-content-sidebar-btn-wrap button {
  margin-left: 0 !important;
  font-size: 13px;
}
.sidebar-single-content-product {
  margin-top: 30px;
}
.sidebar-single-content-product-single-item {
  margin-bottom: 18px;
  margin-top: 0;
}

.sidebar-single-content-product-single-item h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
}
.sidebar-single-content-product-single-item.course-tag-area > h4 {
  margin-bottom: 12px;
}
.sidebar-single-content-product-single-item p {
  color: #74788d;
}
.sidebar-single-content-product-single-item button {
  margin-right: 10px;
  background: #e6f0fe;
}
.page-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.page-title-wrap > * > * {
  margin-bottom: 0;
}
.single-package-data-column {
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
}
.view-all-single-package-main-wrap {
  padding: 70px 0;
  min-height: 85vh;
}
.single-package-inner-main-wrap > .row > * {
  margin-bottom: 25px;
}
.single-package-inner-main-wrap .border-0.text-center.py-4.card {
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  border-radius: 8px;
}
.single-package-data-column-left > * {
  margin-bottom: 12px;
}

.single-package-data-column-left > button {
  margin-bottom: 0;
}
.single-package-data-column-right {
  text-align: center;
}

.single-package-data-column-right > * {
  height: 100%;
}
.package-inner-items-wrap {
  display: flex;
  justify-content: center;
  min-height: 650px;
  align-items: center;
  text-align: center;
}
.package-inner-items-wrap a {
  text-decoration: none;
}
.feedback-review-count > h6 {
  margin-top: 10px;
}
.sidebar-single-content-product.tag-mobile-show {
  display: block;
}
.sidebar-single-content-product.tag-desktop-show {
  display: none;
}
.sidebar-single-content-product-single-item.course-tag-area button {
  margin-right: 10px;
  background: #e6f0fe;
  margin-bottom: 10px;
  font-size: 13px;
}
.student-courses-card-button.facilitator-button-area-wrap {
  justify-content: flex-start;
  gap: 15px;
}
.my-course-card-slingle-title.card-title.h5 {
  text-align: left;
  margin: 15px 0;
}
.MuiSvgIcon-root {
  font-size: 20px !important;
}

.empty-package-area-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 56vh;
}
.empty-package-area-wrap img {
  display: inline-block;
  margin-bottom: 15px;
}
.empty-package-area-wrap h6 {
  font-size: 18px !important;
  margin-bottom: 15px;
}
.empty-package-area-wrap a {
  text-decoration: none;
  padding: 10px 30px;
  color: #fff;
  background: #0052cc;
  margin-top: 15px;
  display: inline-block;
  border-radius: 8px;
}

@media only screen and (max-width: 450px) {
  .package-all-content-details table.table.table-borderless tr * span {
    display: block;
    width: 120px;
  }
}
