@use "sass:math";

.custom-spiner-main-wrap {
  $base: #263238;
  $lite: #fff;
  $brand: #ff3d00;
  $size: 48px;

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $base;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $brand;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  // Loaders

  .loader-11 {
    width: $size;
    height: $size;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: $lite $lite transparent transparent;
    animation: rotation 1s linear infinite;
    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid;
      border-color: transparent transparent $brand $brand;
      width: math.div($size, 8px);

      height: $size - 8px;
      border-radius: 50%;
      animation: rotationBack 0.5s linear infinite;
      transform-origin: center center;
    }
    &:before {
      width: $size - 16px;
      height: $size - 16px;
      border-color: $lite $lite transparent transparent;
      animation: rotation 1.5s linear infinite;
    }
  }

  /* keyFrames */

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes rotationBreak {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
