/* ========================= */
.dashboard-main-wrap {
  padding: 70px 0;
  min-height: 86vh;
}

.dashboard-body-content {
  text-align: center;
  margin: 20px 0;
  padding: 60px 30px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgb(18 38 63 / 3%);
  border-radius: 8px;
  background: #fff;
}
.dashboard-course-info {
  border: 1px solid #e5e5f0;
  padding: 25px;
  border-radius: 10px;
}
.profile-persion-info > img {
  margin-bottom: 15px;
  height: 112px;
}

.profile-persion-info > p {
  margin-bottom: 40px;
}

.profile-persion-info > h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.dashboard-course-info > h6 {
  font-size: 18px;
  color: #74788d;
  margin-bottom: 10px;
}
.dashboard-course-info h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
}
.dashboard-title h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
}
//=============================================================
.dashboard-mock-interview-top-area {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 42px;
  background-color: #023d95;
  .dashboard-mock-start-right-area {
    @media only screen and (max-width: 991px) {
      text-align: center;
    }
    @media only screen and (max-width: 480px) {
      img {
        width: 100%;
      }
    }
  }
  .dashboard-mock-start-left-area {
    height: 300px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 576px) {
      padding-left: 26px;
    }
    h2 {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 52px;
      @media only screen and (max-width: 767px) {
        font-size: 28px;

        line-height: 40px;
      }
    }
    .mock-interview-start-button {
      background: #fff;
      padding: 10px 25px;
      border-radius: 8px;
      font-size: 15px !important;
      display: inline-block;
      text-decoration: none;
    }
  }
}
//=================================================

.single-card-for-ongoing-package {
  background: #fff;
  min-height: 290px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #efefef;
  .card-banner-image {
    img {
      width: 100%;
    }
  }
  .card-bottom-content {
    .card-bottom-content-top-title {
      padding: 14px;
      padding-bottom: 0;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h6 {
        margin-bottom: 10px;
        font-weight: 600;
        span {
          font-size: 20px !important;
          color: #253642;
        }
      }
      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        line-height: 1;
      }
    }
    hr {
      // margin: 0;
      // margin: 0;
    }
    .card-bottom-content-bottom-progress {
      display: flex;
      width: 100%;
      padding: 14px;
      padding-top: 0;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .progress {
        width: 80%;
        height: 7px;
        .progress-bar {
          background-color: #0052cc;
          transition: width 0.6s ease;
        }
      }
      b {
        font-size: 10px;
        color: #0052cc;
      }
    }
  }
}
//===========================
.dashboard--new-main-content-area-wrap {
  // .slick-arrow.slick-next {
  //   position: absolute;
  //   top: -24px;
  //   right: 0;
  //   color: red;
  //   background: red;
  // }
  .next-arrow-icon {
    position: absolute;
    top: -60px;
    right: 12px;
    width: 34px;
    height: 34px;
    border: 1px solid #999cac;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    color: #999cac;
    cursor: pointer;
    &:hover {
      border: 1px solid #0052cc;
      color: #0052cc;
    }
  }
  .prev-arrow-icon {
    position: absolute;
    top: -60px;
    right: 58px;
    width: 34px;
    height: 34px;
    border: 1px solid #999cac;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    color: #999cac;
    cursor: pointer;
    &:hover {
      border: 1px solid #0052cc;
      color: #0052cc;
    }
  }
}

//=========================
.content-new-body-main-content-items {
  margin-bottom: 44px;
}
.dashboard--new-main-content-area-wrap {
  a.slider-text-info-ongoing-project {
    text-decoration: none;
    color: #253642;
    span {
      color: #74788d;
    }
  }
  .empty-blog-area-wrap {
    width: 100%;
    min-height: 350px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #efefef;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h6 {
      font-size: 20px !important;
      margin: 15px 0;
      color: #74788d;
    }
    a {
      display: inline-block;
      padding: 10px 30px;
      background: #0052cc;
      color: #fff;
      border-radius: 8px;
      text-decoration: none;
      margin-top: 10px;
    }
  }
  /* the slides */
  .slick-slide {
    margin: 0 10;
  }
  /* the parent */
  .slick-list {
    margin: 0 -10;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .ongoing-project-title {
    margin-bottom: 24px;
    h3 {
      font-size: 28px !important;
      font-weight: 700;
      color: #495057;
      line-height: 40px !important;
      margin-bottom: 0;
      @media only screen and (max-width: 767px) {
        font-size: 22px !important;
      }
    }
  }
}

.dashboard-right-sidebar-wrap {
  margin-left: 24px;
  @media only screen and (max-width: 1280px) {
    margin-left: 14px;
  }
  @media only screen and (max-width: 991px) {
    margin-left: 0px;
    margin-top: 40px;
  }
  .sidebar-blog-show-area-right {
    .blog-right-area-title {
      margin-bottom: 20px;
      h3 {
        font-size: 28px !important;
        font-weight: 700;
        color: #495057;
        line-height: 40px !important;
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
          font-size: 22px !important;
        }
      }
    }
    .single-blog-area-wrap-right {
      background: #fff;
      padding: 24px;
      border-radius: 8px;
      max-height: 415px;
      overflow-y: scroll;
      @media only screen and (max-width: 1280px) {
        padding: 14px;
      }
      a {
        text-decoration: none;
        color: black;
      }
      .r-single-blog-item {
        display: flex;
        grid-gap: 10px;
        gap: 10px;

        .r-single-blog-item-left {
          img {
            height: 90px;
            width: 110px;
            object-fit: cover;
            border-radius: 8px;
            @media only screen and (max-width: 1280px) {
              height: 80px;
              width: 100px;
            }
          }
        }
        .r-single-blog-item-right {
          h6 {
            font-size: 16px !important;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 6px;
          }
          p {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}
hr {
  color: #74788d;
}
.blog-post-single-post-right-sidebar {
  .blog-details-sidebar-header-title {
    margin-bottom: 20px;
    h3 {
      font-size: 20px !important;
      font-weight: 700;
      line-height: 32px !important;
      color: #495057;
    }
  }
  a {
    text-decoration: none;
    color: black;
    margin-bottom: 30px;
    display: block;
  }
}
.dashboard-right-sidebar-wrap.single-post-details-page {
  margin-top: 65px;
  .blog-details-single-card-item {
    background: #fff;
    margin-bottom: 24px;
    background: #fff;
    margin-bottom: 24px;
    box-shadow: 0px 0px 20px 0px #efefef;
    border-radius: 10px;
    .blog-image {
      // margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
    .blog-card-bottom-content {
      padding: 24px;
      // hr {
      //   margin: 5px 0;
      // }
      h5 {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .post-author-single-post-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .post-author-part-left {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }
  }
}
.dashboard-feedback-main-area-wrap {
  margin-top: 44px;
  .dashboard-ask-feedback-title {
    margin-bottom: 20px;
    h3 {
      font-size: 28px !important;
      font-weight: 700;
      color: #495057;
      line-height: 40px !important;
      margin-bottom: 0;
      @media only screen and (max-width: 767px) {
        font-size: 22px !important;
      }
    }
  }
  .dashboard-feedback-area-bottom {
    padding: 40px;
    background: #1b3f73;
    border-radius: 8px;
    @media only screen and (max-width: 991px) {
      padding: 24px;
      br {
        &:first-child {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 576px) {
      padding: 24px;
      br {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
    h3 {
      color: #fff;
      font-size: 32px !important;
      line-height: 42px !important;
      margin-bottom: 40px;
      @media only screen and (max-width: 767px) {
        font-size: 26px !important;
      }
    }
    a {
      margin-top: 25px;
      display: block;
      background: #fff;
      text-align: center;
      padding: 12px 30px;
      text-decoration: none;
      border-radius: 8px;
      color: #253642;
      font-size: 14px !important;
    }
  }
}

/////================================================

.student-result-main-area-wrap {
  margin-bottom: 40px;
  .studet-result-title {
    margin-bottom: 20px;
    h3 {
      font-size: 28px !important;
      font-weight: 700;
      color: #495057;
      @media only screen and (max-width: 767px) {
        font-size: 22px !important;
      }
    }
  }
  .result-single-card {
    width: 100%;
    min-height: 300px;
    border: 2px solid;
    padding: 18px;
    position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .select-date-top-right-area {
      position: absolute;
      top: 20px;
      right: 20px;
      select {
        border-radius: 25px;
        padding: 6px 12px;
        background: red;
        color: #fff;
        border: none !important;
        font-size: 11px;
        &:focus-visible {
          outline: none;
        }
      }
    }

    .student-result-single-item-wrap {
      .s-result-icon {
        width: 60px;
        height: 60px;
        border: 2px solid;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto;
        margin-bottom: 10px;
        background: #fff;
        margin-top: 30px;
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .s-result-content {
        p {
          margin-bottom: 10px;
          font-size: 16px !important;
        }
        h4 {
          font-size: 26px !important;
          font-weight: 700;
          margin-bottom: 24px;
        }
      }
      .student-result-request {
        width: 100%;
        background: #0e8ae3;
        display: block;
        padding: 10px 30px;
        color: #fff;
        text-decoration: none;
        border-radius: 8px;
        border: none;
      }
    }
    &.reading-result-card {
      border-color: #0e8ae3 !important;
      background: #f9fcff;
      .s-result-icon {
        border-color: #0e8ae3 !important;
        svg path {
          fill: #0e8ae3;
        }
      }
      .student-result-request {
        background: #0e8ae3;
      }
      .select-date-top-right-area select {
        background: #0e8ae3;
      }
    }
    &.speaking-result-card {
      border-color: #fdac22 !important;
      background: #fffcf8;
      .s-result-icon {
        border-color: #fdac22 !important;
        svg path {
          fill: #fdac22;
        }
      }
      .student-result-request {
        background: #fdac22;
      }
      .select-date-top-right-area select {
        background: #fdac22;
      }
    }
    &.listening-result-card {
      border-color: #04b200 !important;
      background: #f9fff9;
      .s-result-icon {
        border-color: #04b200 !important;
        svg path {
          fill: #04b200;
        }
      }
      .student-result-request {
        background: #04b200;
      }
      .select-date-top-right-area select {
        background: #04b200;
      }
    }
    &.writing-result-card {
      border-color: #5d5cdd !important;
      background: #f9f9ff;
      .s-result-icon {
        border-color: #5d5cdd !important;
        svg path {
          fill: #5d5cdd;
        }
      }
      .student-result-request {
        background: #5d5cdd;
      }
      .select-date-top-right-area {
        select {
          border-radius: 25px;
          padding: 6px 12px;
          background: red;
          color: #fff;
        }
      }
      .select-date-top-right-area select {
        background: #5d5cdd;
      }
    }
  }
}
//////////////////////=============================

.dashboard--new-main-content-area-wrap.blog-details-page {
  background: #fff;
  margin-top: 65px;
  > img {
    width: 100%;
  }
  .blog-details-content-body-area-wrap {
    padding: 50px;
    h6 {
      margin-bottom: 10px;
      font-size: 12px;
      text-transform: uppercase;
      color: #979699;
      font-weight: normal;
      b {
        color: #6f7478;
      }
    }
    h3 {
      font-size: 30px !important;
      line-height: 42px !important;
      font-weight: 700;
    }
    p {
      line-height: 22px;
      margin-bottom: 5px;
      color: #74788d;
    }
    .post-author-profile-info-area {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 40px;
      .profile-info-left {
        img {
          width: 45px;
          height: 45px;
        }
      }
      .profile-info-right {
        h4 {
          font-size: 18px;
          margin-bottom: 8px;
          line-height: 1;
        }
        p {
          margin-bottom: 0;
          line-height: 1;
        }
      }
    }
  }
}

//==========Graph====================
.graph-card-wrap {
  margin-top: 44px;
  .student-graph-area-header {
    margin-bottom: 20px;
    h3 {
      font-size: 28px !important;
      font-weight: 700;
      color: #495057;
      line-height: 40px !important;
      margin-bottom: 0;
      @media only screen and (max-width: 767px) {
        font-size: 22px !important;
      }
    }
  }
  .performance-graph {
    width: 100%;
    margin: auto;
    box-shadow: 0px 0px 10px 0px #efefef;
    background: #fff;
    padding: 24px;
    padding-left: 60px;
    padding-bottom: 60px;
    border-radius: 8px;
    @media only screen and (max-width: 576px) {
      padding-left: 40px;
    }
    .student-performance-graph-data-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      .graph-bg-part {
        // border-bottom: 1px solid #f5f5f5;
        height: 38px;
        display: flex;
        align-items: center;
        color: #c2c1c1;
        font-size: 12px;
        position: relative;
        .graph-bg-single-lavel {
          position: absolute;
          left: -30px;
          bottom: -6px;
          width: 100%;
          span {
            display: block;
            text-align: right;
            width: 20px;
            @media only screen and (max-width: 576px) {
              font-size: 10px !important;
            }
          }
          &:after {
            width: 100%;
            height: 1px;
            background: #f5f5f5;
            content: "";
            position: absolute;
            left: 30px;
            bottom: 6px;
          }
          &.achivement-seven-plus {
            color: #0052cc;

            span {
              font-weight: 700;
            }
            &:after {
              width: 100%;
              height: 1px;
              background: #0052cc;
              content: "";
              position: absolute;
              left: 30px;
              bottom: 6px;
            }
          }
        }
      }
      .student-exam-graph {
        display: flex;
        justify-content: space-around;
        position: absolute;
        width: 100%;
        bottom: 0;
        align-items: baseline;

        @media only screen and (max-width: 450px) {
          justify-content: space-between;
        }
        .single-exam-data-bar {
          width: 60px;
          height: 34px;
          background: red;
          position: relative;
          @media only screen and (max-width: 576px) {
            width: 35px;
          }
          @media only screen and (max-width: 450px) {
            width: 24px;
          }
          &:hover {
            .hover-graph-mark-view {
              span {
                visibility: visible !important;
              }
            }
          }
          h4 {
            position: absolute;
            font-size: 13px;
            bottom: -34px;
            color: #b9b5b5;
            font-weight: 400;
            display: block;
            text-align: center;
            width: 100%;
            @media only screen and (max-width: 576px) {
              font-size: 10px;
              bottom: -32px;
              width: 40px;
              transform: rotate(45deg);
              left: -5px;
            }
          }
          .score-position-average {
            .hover-graph-mark-view {
              position: absolute;
              bottom: 0;
              span {
                color: #000000;
                transform: unset;
                top: -15px;
                left: 0;
                display: block;
                text-align: center;
                width: 60px;
                visibility: hidden;
                @media only screen and (max-width: 576px) {
                  width: 40px;
                  font-size: 9px !important;
                }
              }
            }
            &.score-zero {
              position: absolute;
              width: 100%;
              height: 190px;
              background: #dddc;
              top: 0px;
            }
            &.score-one {
              position: absolute;
              width: 100%;
              height: 183px;
              background: #dddc;
              top: 0px;
            }
            &.score-one-half {
              position: absolute;
              width: 100%;
              height: 180px;
              background: #dddc;
              top: 0px;
            }
            &.score-two {
              position: absolute;
              width: 100%;
              height: 176px;
              background: #dddc;
              top: 0px;
            }
            &.score-two-half {
              position: absolute;
              width: 100%;
              height: 172px;
              background: #dddc;
              top: 0px;
            }
            &.score-three {
              position: absolute;
              width: 100%;
              height: 169px;
              background: #dddc;
              top: 0px;
            }
            &.score-three-half {
              position: absolute;
              width: 100%;
              height: 165px;
              background: #dddc;
              top: 0px;
            }
            &.score-four {
              position: absolute;
              width: 100%;
              height: 162px;
              background: #dddc;
              top: 0px;
            }
            &.score-four-half {
              position: absolute;
              width: 100%;
              height: 157px;
              background: #dddc;
              top: 0px;
            }
            &.score-five {
              position: absolute;
              width: 100%;
              height: 152px;
              background: #dddc;
              top: 0px;
            }
            &.five-point-five {
              position: absolute;
              width: 100%;
              height: 114px;
              background: #dddc;
              top: 0px;
            }
            &.score-six {
              position: absolute;
              width: 100%;
              height: 76px;
              background: #dddc;
              top: 0px;
            }
            &.six-point-five {
              position: absolute;
              width: 100%;
              height: 38px;
              background: #dddc;
              top: 0px;
            }
            &.score-saven {
              position: absolute;
              width: 100%;
              height: 0px;
              background: #dddc;
              top: 0px;
            }
            &.saven-point-five {
              position: absolute;
              width: 100%;
              height: 38px;
              background: #dddc;
              top: 0px;
            }
            &.score-eight {
              position: absolute;
              width: 100%;
              height: 76px;
              background: #dddc;
              top: 0px;
            }
            &.eight-point-five {
              position: absolute;
              width: 100%;
              height: 114px;
              background: #dddc;
              top: 0px;
            }
            span {
              position: absolute;
              display: block;
              transform: rotate(90deg);
              font-size: 10px !important;
              width: 70px;
              right: -45px;
              top: 33px;
              color: #979699;
            }
          }
        }
      }
    }
    .exam-filtering-top-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      .exam-filtering-navbar-left {
        background: #e6eefa;
        border-radius: 8px;
        margin-left: -30px;
        ul {
          padding-left: 0;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          li {
            list-style: none;
            padding: 6px 25px;
            color: #0052cc;
            cursor: pointer;
            @media only screen and (max-width: 576px) {
              padding: 6px 12px;
              font-size: 12px;
            }
            @media only screen and (max-width: 450px) {
              padding: 4px 8px;
              font-size: 11px;
            }
            &.filter-active {
              background: #0052cc;
              color: #fff;
              border-radius: 8px;
              margin: 4px;
            }
          }
        }
      }
      .exam-filtering-date-right {
        select.form-select {
          font-size: 13px;
          padding: 9px 16px;
          @media only screen and (max-width: 576px) {
            position: relative;
            left: -30px;
            padding: 6px 10px;
          }
        }
      }
    }
  }
}
.user-profile-info-wrap {
  background: #013d97;
  color: #fff;
  padding: 10px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-radius: 6px;
  p {
    a {
      color: #fff;
      display: inline-block;
      padding: 0px 6px;
      padding-left: 0;
      // text-decoration: none;
    }
  }
  button {
    background: none;
    border: none;
    line-height: 1;
    padding: 0;
  }
}
