@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "./assets/Scss/common.scss";
$pmx_primary: #0052cc;
$pmx_success: #2ca67a;
$pmx_primary_hover: #ffb570;
$pmx_primary_active_bg: #ffa9591a;
$pmx_border: #d9d9d9;
$pmx_border_danger: #f46a6a;
$pmx_gray: #bdbdbd;
$pmx_light_blue: #8181a5;
$pmx_secondary: #2ba3c2;
$pmx_dropdown_color: #a8a8a8;
$pmx_blue: #058de0;
body {
  background: #f8f8fb;
  font-family: "Sora", sans-serif;
  font-size: 13px;
  transition: 0.5s;
  scroll-behavior: smooth;
  .new-home-page {
    background: #f8f8fb;
  }
  .scroll-area {
    position: fixed;
    z-index: 9999;
    top: -90px;
    right: 30px;
    width: 50px;
    height: 50px;
    background: #0b2e4e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0, 0, 0, 1.24);
    transition-delay: 0.2s;
    transition-property: all;
    @media only screen and (max-width: 576px) {
      width: 30px;
      height: 30px;
      right: 10px;
    }
    &.moveToTop {
      top: 93.5%;
    }
    img {
      height: 36px;
      position: relative;
      top: 3px;
      @media only screen and (max-width: 576px) {
        height: 20px;
      }
    }
  }
  p {
    font-size: 13px !important;
  }
  span {
    font-size: 13px !important;
  }
  a {
    font-size: 13px !important;
  }
  ///=====================================
  .fade.splash-screen-main-wrap.modal.show {
    .modal-content {
      padding: 0;
      border-radius: 10px;
      background: #1d52b2;
      .splash-screen-header {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 999;
        border-bottom: none;
        background: #fff;
        padding: 0;
        border-radius: 50%;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        button.btn-close {
          padding: 0px;
          margin: 0px;
        }
      }
      .modal-body {
        padding: 0;
        @media (min-width: 768px) {
          .desktop-screen {
          }
          .tab-screen {
            display: none;
          }
          .mobile-screen {
            display: none;
          }
        }
        @media (max-width: 767px) {
          .desktop-screen {
            display: none;
          }
          .tab-screen {
            display: none;
          }
          .mobile-screen {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
}

h5 {
  margin-bottom: 0;
}
h6 {
  margin-bottom: 0;
}
body > iframe {
  display: none !important;
}
// .gapsInput {

// }
.gapsInput {
  box-shadow: none;
  position: relative;
  bottom: 7px;
  &:active {
    box-shadow: none;
    outline: none;
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.package-title-area-left-part {
  // margin-bottom: 25px;
  width: 70%;
  p {
    color: #74788d;
  }
  h2 {
    font-weight: 700;
    margin-bottom: 9px;
    font-size: 32px;
  }
}
.student-courses-card {
  a {
    text-decoration: none;
    padding: 0;
    .card-title.h5 {
      color: #253642;
      text-decoration: none;
    }
  }
  p.student-courses-timeline.card-text {
    padding: 0;
  }
}
.student-courses-card-button {
  a {
    padding: 7px 12px;
  }
}
.student-courses-review {
  display: flex;
  margin-bottom: 10px !important;
}
.ieltspackage-main-wrap-all-package {
  p.student-courses-timeline.card-text {
    padding: 10px;
    padding-left: 0;
    padding-top: 0;
  }
}
.react-responsive-modal-modal.Xam-Confirm-Modal-main-wrap {
  width: 584px;
  padding: 40px 30px;
  .start-xam-popup-inner-wrap {
    .popup-form-btn-container {
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
      gap: 8px;
      border-top: 1px solid #f4f4f4;
      padding-top: 20px;
    }
    .start-xam-pro-content-wrap {
      display: flex;
      gap: 10px;

      i {
        position: relative;
        img {
          width: 25px;
        }
      }
      .start-xam-popup-content {
        span {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
        }
        p {
          color: #74788d;
          margin-bottom: 25px;
        }
      }
    }
  }
  button.react-responsive-modal-closeButton {
    display: none;
  }
}
button.btn.button-popup-background-1 {
  background: #e0edff;
  color: #0052cc;
  width: 100px;
}
button.btn.button-popup-background-2 {
  background: #0052cc;
  color: #fff;
  width: 100px;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
li.answer-single-question-palette {
  width: 10% !important;
  height: auto !important;
  span {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
  }
}
.checkbox-ques-serial-no {
  display: inline-flex !important;
  // align-items: center !important;
  margin-bottom: 15px;
  grid-gap: 10px;
  gap: 10px;
  h5.d-inline-block {
    position: relative;
    top: 3px;
    left: 5px;
  }
}
p {
  margin-bottom: 0;
}
.react-responsive-modal-overlay {
  background: rgb(0 0 0 / 80%) !important;
}
button.btn.package-connectbutton.btn.btn-primary {
  height: 44px;
  width: 130px;
  margin-top: 12px;
}

.react-responsive-modal-modal {
  .d-flex.flex-wrap.mt-3 {
    justify-content: center;
  }
}
.react-responsive-modal-modal.submitExamModal {
  min-width: 560px;
  min-height: 350px;
  padding: 30px;
  h6 {
    margin-top: 35px;
  }
  .popup-answer-modal-single {
    margin-top: 15px;
    &.item-1 {
      button {
        background: #cce0ff;
        border-radius: 8px;
        display: block;
        width: 100%;
        border: none;
        padding: 8px 15px;
        color: #0052cc;
      }
    }
    &.item-2 {
      button {
        background: #0052cc;
        border-radius: 8px;
        display: block;
        width: 100%;
        border: none;
        padding: 8px 15px;
        color: #fff;
      }
    }
  }
}

.answer-checkbox-mainwrap {
  .btn-primary {
    background: #cce1ff !important;
    border-color: #cce1ff !important;
    &:hover {
      color: #fff;
      background-color: #cce1ff !important;
      border-color: #cce1ff !important;
    }
  }
}
.ques-answer-modal-container > .row > * {
  padding-left: 7px;
  padding-right: 7px;
}
// .answer-checkbox-mainwrap .btn-primary {
//   background: #cce1ff !important;
//   border-color: #cce1ff !important;
// }
.question-fill-inThe-gap-wrap span {
  margin-bottom: 0;
}
.fill-the-gaps-comp-main-wrap > * {
  line-height: 26px;
}
.ques-serial > p {
  line-height: 26px;
}
.ques-serial > h5 {
  margin-bottom: 20px;
  font-weight: 700;
}
.ques-title p {
  color: #0052cc;
}
.ques-sl-no-main.mcq-ques {
  display: flex;
  align-items: center;
  gap: 10px;
}
.question-fill-inThe-gap-wrap span {
  margin-bottom: 0 !important;
}
.col-lg-6 {
  transition: 0.5s;
}
.fill-the-gaps-comp-main-wrap {
  margin-top: 30px;
}
.ques-p-gap-main-wrap {
  padding: 30px 0px 15px 0px;
}
.fill-the-gaps-comp-main-wrap {
  margin-top: 30px;
  margin-bottom: 30px;
}
.answer-checkbox-mainwrap {
  margin-bottom: 15px;
}
.sub-title-header {
  margin: 30px 0;
}
.react-responsive-modal-modal.question-palette-modal {
  max-width: unset;
  width: 670px;
  .ques-answer-modal-container {
    h3 {
      margin-bottom: 6px;
    }
    p {
      margin-bottom: 25px;
      color: #74788d;
    }
  }
}

.ques-popup-answer-wrap.text-center h4 {
  margin-bottom: 10px;
}
.ques-popup-answer-wrap.text-center p {
  margin-bottom: 30px;
  color: #74788d;
}
.question-fill-inThe-gap-wrap {
  display: initial;
}
.exam-body-main.click-icon-fixed-side {
  .col-lg-6 {
    .passage-right-area-main-wrap {
      position: relative;
      border-left: none;
      &::after {
        content: "";
        width: 15px;
        height: 100vh;
        background: #e2e2e2;
        position: fixed;
        left: 0;
        top: 0;
      }
      .reading-passage-wrap.right {
        img {
          position: fixed;
          top: 60px;
          left: 0;
          transition: 0.5s;
          z-index: 9;
        }
      }
    }
  }
}
.exam-body-main.click-icon-fixed-side {
  .col-lg-6 {
    .passage-left-area-main-wrap {
      .reading-passage-wrap.left {
        > img {
          position: fixed;
          top: 60px;
          right: 0;
          transition: 0.5s;
        }
      }
    }
  }
}

.exam-body-main.click-icon-fixed-side {
  .question-right-area-hidden-class {
    .passage-right-area-main-wrap {
      position: relative;
      border-left: none;
      &::after {
        content: "";
        width: 15px;
        height: 100vh;
        background: #e2e2e2;
        position: fixed;
        left: 0;
        top: 0;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .single-choose-single-item {
    padding: 40px 4px !important;
    > h4 {
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 13px !important;
      color: #1e266d;
      line-height: 19px !important;
    }
  }
  .single-choose-single-item br {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .student-hero-content {
    text-align: center;
    small {
      padding-right: 0 !important;
      margin-bottom: 45px;
    }
  }
  img.round-shape-review-area {
    display: none;
  }
  // .herp-bottom-item > * > * {
  //   position: relative;
  //   width: 100% !important;
  // }
  .herp-bottom-item {
    .student-hero-left-b-card {
      position: relative;
      bottom: 0px;
      width: 100% !important;
      // margin-bottom: 25px;
    }
  }
  // .herp-bottom-item {
  //   position: relative;
  //   margin-top: -125px;
  // }
  .gmat-package-main-wrap.gre > * > .row {
    flex-direction: row-reverse;
  }
}

.form-control {
  background-color: #f6f7f9;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  color: #727d88;
  font-weight: 300;
  &:focus {
    color: unset !important;
    border: 1px solid rgba(0, 0, 0, 1) !important;
    border-color: #000 !important;

    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
  }
}
.profile-picture-area {
  .form-control {
    background-color: #f6f7f9;
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    color: #727d88;
    font-weight: 300;
    &:focus {
      color: unset !important;
      border: 1px solid rgba(0, 0, 0, 1) !important;
      border-color: #000 !important;

      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
    }
  }
}

.react-responsive-modal-modal.gift-package-popup-modal {
  max-width: unset;
  min-width: 800px;
  padding: 0;
  .modal-header-top-wrap {
    padding: 15px;
    text-align: center;
    font-size: 18px;
    background: #d6e7ff;
    font-weight: 600;
  }
  .gift-package-content-details {
    padding: 25px 20px;
    padding-bottom: 0;
    overflow: scroll;
    min-height: 400px;
    .package-received-persion-list {
      .form-control {
        font-size: 13px;
        border: 1px solid #dddddd00 !important;
        background: #f6f7f9;
      }
      label {
        font-size: 13px;
        color: #495057;
      }
    }
    .student-courses-card > .card {
      border-radius: 10px;
      position: relative;
      box-shadow: 0px 12px 24px rgb(18 38 63 / 3%);
      overflow: hidden;
      border: 1px solid #d3d3d300;
    }
    .package-received-persion-list button {
      padding: 8px 20px;
      font-size: 14px;
    }
  }
}
.react-responsive-modal-overlay.gift-page-custom-Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}
li.dropdown-mobile-show-desktop-hide .dropdown-menu.show {
  top: 34px !important;
  left: unset;
  right: 0;
  width: 15rem;
}
.acoount-email-password-area .row .mb-3 {
  margin-bottom: 20px !important;
  > * {
    position: relative;
    .invalid-feedback {
      left: 0;
    }
  }
}

.react-responsive-modal-modal.backConfirmModal {
  .popup-answer-modal-single.item-1 {
    padding-right: 40px;
    img {
      min-width: 100%;
    }
  }
  .popup-answer-modal-single.item-2 {
    text-align: left;
    h3 {
      font-weight: 700;
      margin-bottom: 15px;
    }
    // p {
    // }
    button {
      background: #0052cc;
      border-radius: 8px;
      display: inline-block;
      border: none;
      padding: 10px 25px;
      color: #fff;
      margin-top: 25px;
    }
  }
}

.coupon_message {
  background-color: #dc3545;
  padding: 5px 0;
  position: sticky;
  top: 70px;
  z-index: 9;
  .coupon_text {
    word-spacing: 4px;
  }
}
.footer-left p {
  color: #fff;
}
.alert {
  position: relative;
  padding: 8px;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 15px;
  font-size: 11px;
}

.button {
  padding: 0.7rem 2rem;
  font-size: 16px;
  border-radius: 5px;
  transition: all 0.4s;
  border: none;
  text-decoration: none;
}

.button_primary {
  background-color: $pmx_primary !important;
  // background: transparent !important;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: pmx_primary_hover !important;
  }
}

.filter {
  height: 50px !important;
}

.button_success {
  background-color: $pmx_success !important;
  // background: transparent !important;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: $pmx_success !important;
  }
}

.button_danger {
  background-color: $pmx_border_danger !important;
  // background: transparent !important;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: $pmx_border_danger !important;
  }
}

.btn_outer_secondary {
  background-color: transparent !important;
  border: 2px solid $pmx_secondary !important;
  outline: none;
  color: $pmx_secondary !important;
  transition: all 0.4s;
  // &:hover {
  //   background-color: $pmx_secondary !important;
  //   color: #fff !important;
  // }
}

.btn_outer_primary {
  background-color: transparent !important;
  border: 2px solid $pmx_primary !important;
  outline: none;
  color: $pmx_primary !important;
  font-weight: 500;
  transition: all 0.4s;
  // &:hover {
  //   background-color: $pmx_primary !important;
  //   color: #fff !important;
  // }
}

.btn_outer_Blue {
  background-color: transparent !important;
  border: 2px solid $pmx_blue !important;
  outline: none;
  color: $pmx_blue !important;
  font-weight: 500;
  transition: all 0.4s;
  // &:hover {
  //   background-color: $pmx_blue !important;
  //   color: #fff !important;
  // }
}

.btn_Blue {
  background-color: $pmx_blue !important;
  border: 2px solid $pmx_blue !important;
  color: #fff !important;
  font-weight: 500;
  transition: all 0.4s;
  // &:hover {
  //   background-color: $pmx_blue !important;
  //   color: #fff !important;
  // }
}
h6.no-register-redirect-page {
  margin-top: 24px;
  font-size: 20px;
  line-height: 28px;
  a {
    font-size: 20px !important;
  }
}

//=============
.empty-package-page-view-area {
  height: 62vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  > div {
    img {
      display: inline-block;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px !important;
      color: #74788d;
      line-height: 28px;
      a {
        font-size: 18px !important;
        text-decoration: none;
      }
    }
  }
}
