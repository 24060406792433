//=========================
.our-student-say-full-area {
  padding: 70px 0;
  .our-student-say-area-title {
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
    h3 {
      color: rgba(30, 38, 109, 1);
      font-weight: 700;
    }
  }
  .our-student-say-slider-area {
    .next-arrow-image-circle-icon {
      position: absolute;
      top: 50px;
      right: 0;
      z-index: 99;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 27px;
      }
      img {
        width: 60px !important;
        height: 60px !important;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .prev-arrow-image-circle-icon {
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 99;
      @media (max-width: 767px) {
        top: 27px;
      }
      cursor: pointer;
      img {
        width: 60px !important;
        height: 60px !important;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .our-student-single-slider {
      display: flex !important;
      justify-content: center;
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      .student-slider-quotes-icon-down {
        position: absolute;
        right: 60px;
        bottom: -50px;
        z-index: -1;
        @media (max-width: 767px) {
          right: 0px;
          bottom: -40px;
        }
        svg {
          width: 100px;
          opacity: 0.7;
          @media (max-width: 767px) {
            width: 70px;
          }
        }
      }
      .student-slider-quotes-icon-up {
        position: absolute;
        left: 60px;
        bottom: 0px;
        z-index: -1;
        @media (max-width: 767px) {
          left: 0px;
          bottom: 70px;
        }
        svg {
          width: 100px;
          opacity: 0.7;
          @media (max-width: 767px) {
            width: 70px;
          }
        }
      }
      .our-student-single-slide-inner-content {
        img {
          width: 150px !important;
          height: 150px;
          margin: auto;
          margin-bottom: 40px;
          border-radius: 50%;
          @media (max-width: 767px) {
            width: 110px !important;
            height: 110px;
          }
        }
        p {
          font-size: 16px !important;
          width: 80%;
          margin: auto;
          color: rgba(81, 105, 134, 1);
          @media (max-width: 767px) {
            width: 100%;
            font-size: 13px !important;
          }
        }
      }
    }
    .slick-dots {
      //   bottom: -50px;

      display: flex !important;
      justify-content: center;

      li button:before {
        content: "";
        border-radius: 50%;
        background: rgba(217, 217, 217, 1);
        opacity: 0.5;
        width: 16px;
        height: 16px;
      }
      li.slick-active {
        button:before {
          content: "";
          border-radius: 50%;
          background: rgba(0, 82, 204, 1);
          opacity: 1;
        }
      }
    }
  }
}
