//==================================
.new-home-facilitor-full-area {
  padding: 70px 0;
  .new-home-facilitor-left-content {
    max-width: 550px;
    @media (max-width: 1100px) {
      max-width: 450px;
    }
    h2 {
      font-size: 40px;
      font-weight: 600;
      color: #1e266d;
      line-height: 54px;
      @media (max-width: 1100px) {
        font-size: 34px !important;
        line-height: 46px;
      }
      @media (max-width: 767px) {
        font-size: 30px !important;
        line-height: 40px;
      }
    }
    p {
      font-size: 16px !important;
      line-height: 26px;
      @media (max-width: 767px) {
        font-size: 14px !important;
        line-height: 26px;
      }
    }
  }
  .new-home-facilitor-right-image img {
    width: 100%;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
}
