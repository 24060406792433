.result-inner-area {
  padding: 50px 0;
  min-height: 90vh;
}

.result-inner-area > h3 {
  margin-bottom: 30px;
  font-size: 20px !important;
  display: flex;
  align-items: center;
  line-height: 32px !important;
  gap: 7px;
  span {
    display: block;
    line-height: 1;
    i {
      font-size: 18px;
      display: inline-block;
    }
  }
}
.course-review-area-exam-page > span {
  color: #ffc150;
}
span.count-review-exam-page {
  color: #000;
  padding-left: 10px;
}
.course-review-area-exam-course-title {
  margin: 5px 0;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.exam-result-profile---content {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  margin: 0;
}
.exam-result-feedback---content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  padding: 30px;
  gap: 20%;
}
.course-result--profile-box > .card-img-top {
  height: 160px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.rounded-circle.result-profile-page {
  width: 60px;
  height: 60px;
}

.result-main-area-inner-box {
  background: #fff;
  padding: 40px 0;
  border-radius: 10px;
}
.result-main-area-inner-box h4 {
  position: relative;
  padding-left: 20px;
}
.course-result--profile-box.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0 #dddddd5e;
}
.result-main-area-inner-box h4:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #fddd;
  left: -10 !important;
  top: 0;
  background: #ddd;
}
.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 20px;
}
.exam-ques-your-position > ul > li {
  list-style: none;
  font-weight: 600;
}
.exam-ques-your-position > ul > li {
  list-style: none;
}

.exam-ques-your-position > ul {
  display: flex;
  padding-left: 0;
  gap: 30px;
}

li.your-exam-answer {
  border-bottom: 2px solid #2068d2;
  color: #2068d2;
}
.course-result--profile-area {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  margin: 24px 40px;
}
.course-result--profile-area.first-result-box {
  margin-bottom: 50px;
}
.course-result--profile-area > table {
  text-align: center;
}

.course-result--profile-area > table > thead {
  background: #f3f3f3;
}
span.ques-id-serial {
  width: 30px;
  height: 30px;
  background: #bcd2e7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-result--profile-area > table > tbody > tr {
  border-bottom: 1px solid #f3f3f3;
}
.course-result--profile-area > table > tbody > tr:last-child {
  border-bottom: none;
}
span.correct-ans {
  color: #16eb52 !important;
  font-size: 22px !important;
}
span.false-ans {
  color: #ff1313 !important;
  font-size: 22px !important;
}
.course-result--profile-area > table > thead > tr > th {
  color: #253642 !important;
  font-size: 18px;
  font-weight: 400;
}
.course-result--profile-area > table > tbody > tr > td {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}
.course-result--profile-area > table > tbody > tr > td > span {
  font-weight: 400;
  font-size: 16px;
  color: #253642;
}

.result-data-field-wrap {
  .single-result-data-wrap {
    display: flex;
    //   align-items: center;
    gap: 10px;
    width: 100%;
    .result-title {
      label {
        margin-bottom: 0;
        font-size: 14px;
        width: 150px;
        font-weight: 500;
      }
      p {
        margin-bottom: 0;
        font-size: 16px;
        width: 150px;
      }
    }
    .result-field {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

// ================
.exam-result-profile---content > span {
  font-weight: 600;
  font-size: 20px;
}

.exam-result-feedback---item {
  h6 {
    font-weight: 600;
    font-size: 22px;
  }
  p {
    color: #74788d;
    margin-bottom: 10px;
  }
}
.result-main-area-inner-box > h4 {
  margin-left: 40px;
}

.result-inner-area {
  .result-main-area-inner-box {
    > div > h4 {
      margin-left: 40px;
      margin-bottom: 30px;
    }
    .question-right-area-hidden-show-class {
      border: 1px solid #c4c4c4;
      padding: 0 0px;
      .exam-body-main {
        margin-top: 0;

        .passage-left-area-main-wrap {
          right: 0px;
        }
      }
    }
  }
}
.exam-name-ques-part-name-package-name {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  span {
    color: #74788d;
  }
}
.result-page-heder-page-title-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  i {
    font-size: 24px;
  }
}
.listening-exam-inner-wrap {
  border: 1px solid #ddd;
}

.result-main-area {
  .result-inner-area {
    .result-main-area-inner-box {
      .question-right-area-hidden-show-class {
        .exam-body-main {
          .answer-checkbox-mainwrap {
            .d-flex.align-items-center.py-2 {
              button.gapsInput {
                bottom: 0px;
              }
            }
          }
          .ques-serial {
            .d-flex.align-items-center.py-2 {
              > * {
                button.gapsInput {
                  bottom: 0px;
                  width: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.result-data-field-wrap.mt-2.speaking-result-page {
  margin: 40px;
  background: #fafafa;
  padding: 40px;
  border-radius: 10px;
  .result-field {
    .form-control:disabled,
    .form-control[readonly] {
      background-color: #fff;
      opacity: 1;
      border: 1px solid #aaaaaa !important;
      color: #253642 !important;
    }
  }
}
.q-p-header.answer-title-left h4 {
  padding-left: 0;
}
.result_title {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  gap: 14px;
  align-items: center;
  color: #0052cc;
  font-weight: 700;
  span {
    display: block;
  }
}
