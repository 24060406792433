/* ==========================  */
.profile-view-inner-wrap {
  padding: 70px 0;
  min-height: 86vh;
}
.profile-view-area h2 {
  margin-bottom: 20px;
  font-weight: 700;
}
.profile-picture-area {
  width: 550px;
  margin: auto;
  background: #fff;
  padding: 26px 30px;
  border-radius: 10px;
  label.form-label {
    margin-top: 0px;
  }
  > h6 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
  }
  .profileCard .profile {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
  }
}
.profile-view-inner-wrap > h3 {
  margin-bottom: 50px;
  font-weight: 600;
}
form.profile-inner-form-item > img {
  width: 50px;
  margin-right: 20px;
}
.profile-picture-wrap {
  margin-bottom: 30px;
  position: relative;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  .profile-page-image-upload {
    display: none;
  }
}
.profile-save-btn {
  width: 100%;
}

.profileRadio legend {
  font-size: 13px;
}
fieldset.jxhbsdgdvbch {
  margin-bottom: 0 !important;
  .form-control {
    background: none;
  }
}
button.profile-submit-page-btn {
  width: 100%;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .profile-picture-area {
    width: 100%;
    margin: auto;
    background: #fff;
    padding: 14px 14px;
    border-radius: 10px;
    .settingsProfile {
      width: 80px;
      height: 80px;
      .profile img {
        width: 100%;
        height: 100%;
        object-fit: unset !important;
      }
    }
  }
  .student-login-main-wrap {
    .form-control {
      border: none !important;
      background: #f6f7f9 !important;
      padding: 10px 15px !important;
      border-radius: 10px;
      font-size: 14px;
      color: #727d88;
      font-weight: 300;
    }
  }

  .profile-picture-area > h6 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
  }
}
//==============================
.profile-image-upload-image-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;

  .hover-image-upload-button-view {
    position: absolute;
    z-index: 9;
    background: #74788dd1;
    width: 96%;
    height: 96%;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: 0.4s;
    border-radius: 50%;
    cursor: pointer;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .settingsProfile {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &:hover {
    .hover-image-upload-button-view {
      transform: scale(1);
      transition: 0.4s;
    }
  }
}
