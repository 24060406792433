.student-account-form-wrap {
  width: 650px;
  background: #fff;
  border-radius: 10px;
  padding: 60px;
  h5 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
  }
  button.btn.btn-primary {
    padding: 8px 25px;
  }
  span {
    color: #74788d;
  }
}
