.video-tutorial-main-area-wrap {
  min-height: 86vh;
  padding: 60px 0;
  .video-tutorial-header {
    h4 {
      margin-bottom: 26px;
    }
  }
  .video-tutrial-single-item {
    width: 100%;
    min-height: 250px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
    border-radius: 8px;
    background: #fff;

    padding: 15px;
    .video-tutorial-top {
      height: 200px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: #00000050;
        position: absolute;
      }
      button {
        position: relative;
        z-index: 1;
      }
    }
    .video-tutrial-bottom {
      padding: 10px 0;
      h4 {
        margin-bottom: 0;
      }
    }
  }
}
.videoTutorialModal-inner-wrap {
  padding: 30px;
}
