//==========================
.leaderboard-main-area-wrap {
  .leaderboard-top-area-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    gap: 12px;
    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
    .leaderboard-top-area-left {
      h3 {
        font-size: 28px !important;
        font-weight: 700;
        line-height: 40px !important;
        margin-bottom: 0;
      }
    }
    .leaderboard-top-area-right {
      display: flex;
      gap: 15px;
      @media only screen and (max-width: 500px) {
        gap: 5px;
      }
      .leaderboard-filter-single {
        select.form-select {
          font-size: 13px;
          @media only screen and (max-width: 500px) {
            width: 100px;
          }
        }
      }
    }
  }
  .leaderboard-inner-area-wrap {
    background: #ffff;
    box-shadow: 0px 0px 30px 0px #e9ecef;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
    .leaderboard-top-three {
      background: #1b3f73;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 40px 0;
      padding-bottom: 30px;
      .leaderboard-top-three-inner {
        width: 86%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @media only screen and (max-width: 991px) {
          width: 96%;
        }
        @media only screen and (max-width: 767px) {
          width: 96%;
        }
        @media only screen and (max-width: 576px) {
          width: 100%;
        }
        > * {
          align-items: flex-end;
        }
        .leaderboard-single-persion {
          background: #fff;
          margin: 30px 0;
          // min-height: 220px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 120px;
          border-radius: 8px;
          width: 32%;
          transform: scale(0.9);
          transform-origin: bottom;
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            position: absolute;
            top: -50px;
            @media only screen and (max-width: 767px) {
              height: 70px;
              width: 70px;
              top: -35px;
            }
            @media only screen and (max-width: 576px) {
              height: 60px;
              width: 60px;
              top: -30px;
            }
          }
          h6 {
            position: absolute;
            top: -80px;
            color: #ffc34d;
            font-weight: 700;
            font-size: 18px;
          }
          &.score-topper {
            transform: scale(1.1);
            transform-origin: bottom;
            .leaderboard-profile {
              height: 270px;
              @media only screen and (max-width: 991px) {
                height: 200px;
              }
              @media only screen and (max-width: 767px) {
                height: 180px;
              }
              @media only screen and (max-width: 576px) {
                height: 130px;
              }
              @media only screen and (max-width: 450px) {
                height: 110px;
              }
            }
          }
          .leaderboard-profile {
            text-align: center;
            margin-top: 40px;
            h5 {
              font-size: 22px;
              color: #495057;
            }
            h3 {
              font-weight: 800;
              font-size: 40px;
              margin-top: 8px;
              color: #495057;
            }
          }
        }
      }
    }
    table.table {
      border: none !important;
      margin-bottom: 0;
      vertical-align: middle;
      > * > tr > * {
        font-weight: 400;
        &:first-child {
          padding-left: 20px;
        }
      }
      thead.leaderboard-all-list-header {
        background: #ededed;
        tr {
          th {
            color: #4a687e;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
      &.table-hover > tbody > tr:hover > * {
        --bs-table-accent-bg: #cbe0ff !important;
      }
      &.table-striped > tbody > tr:nth-of-type(odd) > * {
        --bs-table-accent-bg: #f1f7ff !important;
      }
      tr {
        td {
          .user-profile-img img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          .user-profile-pregress {
            display: flex;
            grid-gap: 10px;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            .progress {
              height: 0.5rem;
              width: 80%;
              .progress-bar {
                background-color: #0052cc;
                border-radius: 25px;
              }
            }
            .progress-score {
              h6 {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 600;
                color: #0052cc;
              }
            }
          }
        }
      }
    }
  }
}
//=================================================
.empty-leader-board-container-area {
  padding: 30px 0;
  > h3 {
    font-weight: 700;
    color: #495057;
    font-size: 32px !important;
    line-height: 42px !important;
  }
  .empty-leader-board-main-area-wrap {
    min-height: 75vh;
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 10%;
    @media only screen and (max-width: 767px) {
      padding-top: 15%;
      height: unset;
      min-height: 70vh;
    }
    @media only screen and (max-width: 500px) {
      padding-top: 15%;
      height: unset;
      min-height: 65vh;
    }
    .empty-content-area {
      text-align: center;

      p {
        font-size: 16px !important;
        margin-top: 15px;
        color: #74788d;
      }
      img {
        height: 200px;
      }
    }
  }
}
