.footer-main-area {
  background: #1b283a;
  padding: 50px 0;
  padding-bottom: 20px;
}
.footer-single-item-content > span {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 10px;
}
// .footer-single-item-title {
//   height: 60px;
//   display: flex;
//   align-items: center;
// }
.footer-single-item-title h5 {
  color: #fff !important;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer-single-item-title img {
  height: 60px;
  display: inline-block;
  margin-bottom: 20px;
}
.footer-single-item-content label {
  display: block;
}

.footer-single-item-content input {
  display: block;
  margin: 10px 0;
  border: none;
  padding: 8px 10px;
  border-radius: 10px;
  width: 100%;
  color: #000;
}

.footer-single-item-content button {
  display: block;
  margin: 10px 0;
  border: none;
  padding: 7px 40px;
  border-radius: 10px;
  background: #fff;
  font-weight: 600;
  color: #000;
}
.footer-single-item-content > * {
  color: #fff;
}
.footer-single-item-content > * p {
  color: #fff;
}
// .footer-single-item.footer-item-get-in-touch {
//   // padding-left: 25%;
// }

.mobile-copy-right {
  display: none;
}
.footer-bottom-main-content-wrap {
  padding: 10px 0;
  background: #1b283a;
  hr {
    color: #fff;
  }
  .footer-copyright-area-bottom {
    padding: 4px 0px;
    padding-top: 0;
  }
}
.footer-left-copyright-area p {
  color: rgba(255, 255, 255, 0.386);
}
.footer-right-copyright-area ul {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 0;
  padding: 5px 0;
  padding-bottom: 12px;
  align-items: center;
  li {
    list-style: none;
    a {
      color: rgba(255, 255, 255, 0.386);
      &:hover {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .footer-single-item.footer-item-get-in-touch {
    padding-left: 0%;
  }
  .col-lg-4.col-md-12 > .footer-single-item {
    text-align: center;
  }
  .col-lg-4.col-md-12 > .footer-single-item > .footer-single-item-title {
    display: flex;
    justify-content: center;
  }
  .mobile-copy-right {
    text-align: center;
    display: block;
  }

  .mobile-copy-right p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  p.footer-mobile-hide {
    display: none;
  }

  .footer-single-item-title {
    justify-content: center;
    text-align: center;
  }
  .footer-single-item-content > span {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-bottom: 18px;
    justify-content: center;
    align-items: center;
  }
  .footer-single-item-content {
    text-align: center;
    padding: 1% 8%;
  }
}
@media only screen and (max-width: 770px) {
  .footer-single-item.footer-item-get-in-touch {
    padding-left: 0%;
  }
  .col-lg-4.col-md-12 > .footer-single-item {
    text-align: center;
  }
  .col-lg-4.col-md-12 > .footer-single-item > .footer-single-item-title {
    display: flex;
    justify-content: center;
  }
  .mobile-copy-right {
    text-align: center;
    display: block;
  }

  .mobile-copy-right p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  p.footer-mobile-hide {
    display: none;
  }
}
.ssl-commerce-payment-bank-list img {
  width: 100%;
}
.footer-single-item-content.insights-image a img {
  height: 50px;
}
.footer-single-item-content span > span {
  line-height: 24px;
}
.footer-single-item-content p {
  line-height: 24px;
}
