// @media only screen and (max-width: 1280px) {
//   .create-new-package-request-area-wrap
//     .package-page-create-package
//     img.banner-image-right-area {
//     position: absolute;
//     right: 0;
//     top: unset;
//     height: 86%;
//     bottom: 0;
//   }
// }
@media only screen and (max-width: 1280px) {
  .create-new-package-request-area-wrap
    .package-page-create-package
    img.banner-image-right-area {
    position: absolute;
    height: 260px;
  }
}

@media only screen and (max-width: 991px) {
  .create-new-package-request-area-wrap
    .package-page-create-package
    img.banner-image-right-area {
    position: absolute;
    height: 260px;
    right: unset;
    bottom: 0;
  }
  .student-hero-content > h4 {
    font-size: 36px;
    line-height: 48px;
  }

  .test-single-item {
    h6 {
      font-size: 16px;
    }
    span {
      width: 42px;
      height: 42px;
      img {
        width: 20px;
      }
    }
  }
  .course-single-part-right h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .student-hero-content .form-text {
    font-size: 14px;
    line-height: 24px;
  }
  .people-choose-title h3 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .item.student-review-slider-item h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .footer-left {
    text-align: center;
    p {
      color: #fff;
    }
  }
  .footer-right {
    text-align: unset;
    margin-top: 15px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
      li {
        a {
          margin-left: 0;
        }
      }
    }
  }
  .item.student-review-slider-item {
    padding-left: 0px;
  }
  .footer-single-item.footer-item-get-in-touch.mobile-hide-footer {
    width: 70%;
    margin: auto;
    margin-bottom: 25px;
    text-align: left;
    .footer-single-item-content > span {
      justify-content: flex-start;
      align-items: baseline;
      text-align: left;
    }
  }
  .footer-single-item {
    margin-bottom: 24px;
  }
  .product-details-main-area-wrap-content > .row {
    flex-direction: column-reverse;
  }
  .sidebar-single-content-product.tag-mobile-show {
    display: none;
  }
  .sidebar-single-content-product.tag-desktop-show {
    display: block;
  }
  .package-title-area-left-part h2 {
    margin-bottom: 9px;
    font-size: 28px;
    line-height: 40px;
  }
  .return-policy-main-wrap .return-policy-inner-content-wrap {
    padding: 16px;
    .return-policy-single-item {
      h4 {
        font-size: 18px;
        line-height: 28px;
      }
      h5 {
        font-size: 18px;
        line-height: 28px;
      }
      p {
        margin-bottom: 20px;
        font-size: 13px;
        line-height: 26px;
        color: #232536 !important;
        text-align: justify;
      }
    }

    .return-policy-header {
      text-align: left;
      h3 {
        color: #5756d8 !important;
        margin-bottom: 8px;
        font-size: 22px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .student-hero-content > h4 {
    font-size: 36px;
    line-height: 48px;
  }
  .course-single-part-right h4 {
    font-size: 30px;

    line-height: 40px;
  }
  .test-single-item {
    h6 {
      font-size: 16px;
    }
    span {
      width: 42px;
      height: 42px;
      img {
        width: 20px;
      }
    }
  }
  .course-single-part-right h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .student-hero-content .form-text {
    font-size: 14px;
    line-height: 24px;
  }
  .people-choose-title h3 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .item.student-review-slider-item h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .footer-left {
    text-align: center;
  }
  .footer-right {
    text-align: unset;
    margin-top: 15px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
      li {
        a {
          margin-left: 0;
        }
      }
    }
  }
  .item.student-review-slider-item {
    padding-left: 0px;
  }
  .nav-main-navbar-dropdown-menu-link-wrap {
    min-width: unset;
  }
  .dropdown-desktop-show-mobile-hide.nav-item.dropdown {
    display: none;
  }
  li.dropdown-mobile-show-desktop-hide {
    display: block;
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
  .navbar-link-border.mobile-hide-border {
    display: none;
  }
  .container,
  .container-sm {
    max-width: 100%;
  }
  .course-single-part-left {
    width: 400px;
    margin: auto;
  }
  .course-single-part-right ul {
    padding-left: 0;
    width: 100% !important;
    margin: auto;
    margin-bottom: 40px;
  }
  .course-result--profile-area thead tr th span {
    width: 115px;
    display: block;
  }
  .package-title-area-left-part h2 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 30px;
  }
  .student-courses-card a .card-title.h5 {
    font-size: 16px;
  }
  .student-courses-card-button button {
    font-size: 13px;
  }
  .package-area-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    gap: 0;
    flex-wrap: wrap;
  }
  .ques-right-part-container {
    padding: 24px 10px;
  }
  .result-inner-area > h3 {
    margin-bottom: 30px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    span {
      display: block;
    }
  }

  .nav-main-navbar-dropdown-menu-link-wrap a .ghdjk {
    display: flex;
    align-items: center;
    height: 24px;
    background: #dceaff;
    width: 24px;
    justify-content: center;
    border-radius: 5px;
    img {
      width: 15px;
      height: unset !important;
    }
  }
  .create-new-package-request-area-wrap .package-page-create-package {
    align-items: unset;
    min-height: 500px;
    height: unset;
    justify-content: center;
    text-align: left;
    h2 {
      color: #fff;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 16px;
    }
    img.banner-image-right-area.mobile-show {
      display: block;
      height: unset;
      width: 370px;
      top: unset;
      bottom: 0;
      right: unset;
    }
  }
  .create-new-package-request-area-wrap .create-new-package-request-title h3 {
    font-size: 32px !important;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 30px;
  }
  .people-choose-title h3 {
    font-size: 30px !important;
    margin-bottom: 30px;
    line-height: 42px !important;
  }
}
@media only screen and (max-width: 576px) {
  .people-choose-title h3 {
    font-size: 22px !important;
    line-height: 32px !important;
  }
  .create-new-package-request-area-wrap
    .package-page-create-package
    img.banner-image-right-area {
    position: absolute;
    height: 170px;
    right: unset;
    bottom: 0;
  }
  .create-new-package-request-area-wrap .package-page-create-package {
    padding-left: 15px;
    padding-right: 15px;
  }
  // a.navbar-register-btn.nav-link {
  //   display: none;
  // }
  .course-single-part-left {
    width: 280px;
    margin: auto;
  }
  .course-single-part-right ul {
    padding-left: 0;
    width: 100%;

    margin-bottom: 40px;
  }
  .feedback-review-count h2 {
    font-size: 24px;
  }
  .feedback-single-item-right {
    width: 74%;
  }
  .feedback-review-bar-wrap > .progress {
    width: 54%;
  }
  .feedback-single-item-left.feedback-content-left {
    width: 60px !important;
    text-align: center;
  }
  .feedback-single-item-left.feedback-content-left img {
    height: 30px;
  }
  .feedback-review-count > h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .package-all-content-details-sub-title-time p {
    font-size: 11px !important;
  }
  .package-all-content-details-sub-title-time > p > span {
    line-height: 1;
    font-size: 10px !important;
    color: #0052cc;
  }
  .package-all-content-details-sub-title-time {
    gap: 10px;
  }
  .feedback-review-count > p {
    font-size: 11px !important;
  }
  .feedback-single-item.feedback-review-see-more-feedback button {
    font-size: 12px;
  }
  .feedback-main-wrap.all-comments > *:nth-child(even) {
    margin-left: 40px;
  }
  .footer-single-item-content > * {
    color: #fff;
    font-size: 11px !important;
    line-height: 20px !important;
  }
  .footer-single-item-content {
    text-align: center;
    padding: 1% 0%;
    a {
      img {
        height: 40px;
      }
    }
    &.insights-image a img {
      height: 34px !important;
    }
  }
  .footer-single-item.footer-item-get-in-touch.mobile-hide-footer
    .footer-single-item-content
    > span {
    justify-content: flex-start;
    align-items: baseline;
    text-align: left;
    margin-bottom: 6px;
    span {
      font-size: 11px !important;
    }
  }
  .footer-left p {
    font-size: 11px !important;
    line-height: 20px;
  }
  .footer-right ul {
    gap: 8px;
    li a {
      font-size: 11px !important;
    }
  }
  .feedback-review-count > h6 {
    margin-top: 10px;
    font-size: 13px;
    img {
      height: 18px;
    }
  }
  .exam-result-profile-feedback-view.ps-3 {
    padding-left: 0 !important;
  }
  .package-title-area-left-part {
    margin-bottom: 10px;
    width: 100%;
  }
  .react-responsive-modal-modal.gift-package-popup-modal {
    max-width: unset;
    min-width: unset;
    padding: 0;
    margin: 0;
  }
  .package-all-content-details-title > h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .package-price-information-top-wrap > h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .package-details-content {
    .accordion-flush .accordion-item .accordion-button {
      border-radius: 0;
      font-size: 14px;
    }
  }
  .sidebar-single-content-product-single-item h4 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .package-details-feedback-area > * > h3 {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .single-package-inner-main-wrap.mx-auto {
    width: 100%;
    max-width: unset !important;
  }
  .course-result--profile-area {
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 10px;
    margin: 24px 6px;
  }
  .result-main-area-inner-box > h4 {
    margin-left: 10px;
  }
  .course-result--profile-area.first-result-box {
    .exem-course-result-package.rounded-5.m-0 {
      margin-bottom: 15px !important;
    }
  }
  .result-inner-area .result-main-area-inner-box > div > h4 {
    margin-left: 6px;
    margin-bottom: 30px;
  }
  .result-main-area-inner-box h4 {
    position: relative;
    padding-left: 10px;
  }
  .exam-result-feedback---item {
    margin-bottom: 15px;
  }
  .exam-f-left h5 {
    font-size: 13px;
  }
  .exam-h-marks.exam-h-t-right-button button {
    padding-left: 4px;
    font-size: 12px;
  }
  .checkbox-ques-serial-no.option-select-ans select {
    width: 90px;
    margin-right: 0px;
    font-size: 12px;
  }
  .checkbox-ques-serial-no h5 {
    width: 22px;
    height: 22px;
    background: #e6eafd;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    /* margin-right: 10px; */
    margin-bottom: 0px;
    font-size: 10px;
  }
  .result-main-area
    .result-inner-area
    .result-main-area-inner-box
    .question-right-area-hidden-show-class
    .exam-body-main
    .ques-serial
    .d-flex.align-items-center.py-2
    > *
    button.gapsInput {
    bottom: 0px;
    width: 110px;
    font-size: 11px;
  }
  .exam-result-profile---content {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    border-bottom: 1px solid #ddd;
    padding: 14px 0;
    margin: 0;
    justify-content: center;
  }
  .exam-result-feedback---content {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    padding: 30px;
    grid-gap: 20%;
    gap: 20%;
    margin-top: -10px;
  }
  .passage-single-left-part {
    margin-right: 8px;
  }
  .student-login-main-wrap.signup-page .student-account-form-wrap {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
  }
  .student-login-main-wrap.signup-page {
    padding: 50px 0;
  }
  .footer-single-item-title h5 {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .footer-main-area {
    padding-bottom: 0;
  }
  .footer-single-item.footer-item-get-in-touch.mobile-hide-footer {
    margin-bottom: 8px;
  }
  .create-new-package-request-area-wrap .package-page-create-package h2 {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 16px;
  }
  .ieltspackage-main-wrap-all-package {
    padding: 40px 0;
    padding-bottom: 0;
  }
  .col-lg-4.col-md-12 > .footer-single-item {
    margin-bottom: 12px;
  }
}

//============================
@media only screen and (max-width: 576px) {
  .coupon_message .coupon_text {
    word-spacing: 4px;
    p {
      font-size: 10px !important;
    }
    .position-absolute {
      top: 0 !important;
      i {
        font-size: 17px !important;
      }
    }
  }
  .course-single-part-right h3 {
    font-size: 26px;
    line-height: 36px;
  }
  .footer-bottom-main-content-wrap hr {
    margin: 8px 0;
  }
  .footer-right {
    text-align: unset;
    margin-top: 8px;
  }
  .student-main-navbar-wrap nav.navbar.navbar-expand.navbar-light a img {
    height: 36px;
  }
  .student-main-navbar-wrap a.nav-link.cart-navbar-button img {
    height: 16px !important;
  }
  .d-flex.justify-content-between.align-items-center.create_package_title {
    flex-wrap: wrap;
    gap: 6px;
  }
  .course-single-part-right ul li {
    margin-bottom: 8px !important;
  }
  .people-choose-wrap {
    padding: 80px 0;
  }
  .why-choose-us-items {
    margin: 0px 24px;
  }
  .student-hero-content .form-text {
    font-size: 13px;
    line-height: 24px;
  }
  .empty-draft-page-area-wrap .empty-draft-inner-area img {
    height: 150px;
  }
}
@media only screen and (max-width: 450px) {
  .student-login-main-wrap.signup-page {
    padding: 50px 0;
    display: block;
    width: 90%;
    margin: auto;
  }
  .cart-navbar-button span {
    font-size: 8px !important;
    width: 12px;
    height: 12px;
    right: 6px;
    top: -4px;
  }
  .navbar-link-border {
    width: 1px;
    height: 25px;
    background: #d6d6d6;
    align-items: center;
    margin: 0px 6px;
  }
  .show-student-profile {
    padding-left: 6px !important;
  }
  .dashboard-body-content {
    padding: 60px 16px;
  }
  .course-single-part-right h4 {
    font-size: 26px;
    line-height: 36px;
  }
  .student-hero-content .form-text br {
    display: none;
  }
  .create-new-package-request-area-wrap .package-page-create-package {
    padding-left: 15px;
    padding-right: 15px;
    height: 370px;
    min-height: 370px;
    justify-content: flex-start;
    h2 {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 0px;
    }
  }
  .course-single-part-left {
    width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 350px) {
  .create-own-package-slider-area-wrap
    .create-package-single-slide
    .create-package-slider-right-part
    img {
    height: 210px !important;
  }
}
