.speaking-main-container-wrap {
  padding: 50px 0;
  min-height: 86vh;
  .header-top-button-title {
    h4 {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 25px;
      span {
        display: block;
      }
    }
  }
  .speaking-exam-date-bottom-wrap {
    background: #fff;
    padding: 50px 50px;
    border-radius: 10px;
    margin-top: 50px;
    .speaking-exam-date-content-wrap > * {
      width: 100%;
    }
    .react-calendar__month-view {
      border: 2px solid #f1f1f1;
      padding: 16px 20px;
    }
    .react-calendar {
      border: none;
      margin: auto;
      background: transparent;
    }
    .form-submit-main-time-wrap {
      padding-left: 45px;
      position: relative;
      &:after {
        content: "";
        width: 1px;
        height: 100%;
        background: #c8c8c8;
        position: absolute;
        top: 0;
        left: 17px;
      }
    }
  }
}

.speaking-exam-selected-time {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // gap: 25px;
  .speaking-selected-time-button {
    border: 1px solid #0052cc;
    color: #0052cc;
    padding: 10px 0px;
    border-radius: 7px;
    background: #f8f8fb;
    font-size: 15px;
    // width: 30%;
    cursor: pointer;
    text-align: center;
  }
  .selected {
    background: #5e6b80;
    color: #fff;
  }
  .added {
    background: #42536c;
    color: #fff;
  }
  .disable {
    background: #f8f8fb;
    color: #a2b6d2;
    border: 1px solid #a2b6d2;
    cursor: not-allowed;
  }
}

.form-submit-main-time-wrap {
  .time-selected-submit-btn {
    display: flex;
    justify-content: flex-end;
    .button {
      border: none;
      color: #fff;
      padding: 12px 25px;
      border-radius: 7px;
      background: #0052cc;
    }
  }
}
.speaking-package-listed-confirmation {
  display: flex;
  justify-content: center;
  text-align: center;
  img {
    height: 100px;
    margin-bottom: 25px;
  }
  h3 {
    margin: 15px 0;
    margin-top: 0;
  }
  p {
    margin-bottom: 25px;
  }
  & > * > a {
    border: none;
    color: #fff;
    padding: 12px 25px;
    border-radius: 7px;
    background: #0052cc;
    text-decoration: none;
    display: inline-block;
    margin-top: 15px;
  }
}
.speaking-package-listed-view-wrap {
  text-align: center;
  h3 {
    margin-bottom: 15px;
  }
  h4 {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    gap: 14px;
    align-items: center;
    color: #0052cc;
    font-weight: 700;
    span {
      display: block;
    }
  }
  p {
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 15px;
  }
  a {
    border: none;
    color: #fff;
    padding: 12px 25px;
    border-radius: 7px;
    background: #0052cc;
    text-decoration: none;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
