//==========================

.new-home-article-full-area {
  overflow: hidden;
  position: relative;
  padding: 60px 0;
  background: #f8f8fb;
  &::before {
    width: 50%;
    position: absolute;
    height: 100%;
    background: #f8f8fb;
    top: 0;
    left: 0;
    content: "";
    z-index: 9;
  }
  @media (max-width: 991px) {
    &::before {
      display: none;
    }
  }

  .new-home-article-content-wrap {
    .new-home-article-content-left {
      position: relative;
      z-index: 99;
      padding-right: 10%;
      padding-top: 50px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
        padding-top: 0px;
        padding-right: 0%;
      }
      h2 {
        font-size: 40px;
        font-weight: 600;
        padding-right: 40px;
        color: rgba(30, 38, 109, 1);
        @media (max-width: 767px) {
          font-size: 30px;
          padding-right: 0px;
        }
      }
      p {
        font-size: 18px !important;
        color: rgba(81, 105, 134, 1);
        font-weight: 400;
        @media (max-width: 767px) {
          font-size: 13px !important;
        }
      }
    }

    .new-home-article-slider-area {
      a {
        text-decoration: none;
        color: black;
      }
      button.slick-arrow {
        display: none !important;
      }
      .slick-list {
        margin: 0 -10px;
        padding: 0 50px 0 0 !important;
        overflow: visible !important;
      }
      .slick-slide {
        padding: 0 10px;
      }
      .new-home-single-article-item {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        .new-home-single-article-item-top-image img {
          max-height: 230px;
          object-fit: cover;
          @media (max-width: 767px) {
            height: 200px;
          }
          @media (max-width: 576px) {
            height: 190px;
          }
        }
        .new-home-single-article-item-bottom-content {
          padding: 15px 24px;
          margin-top: 10px;
          @media (max-width: 767px) {
            padding: 8px 14px;
          }
          h5 {
            margin-bottom: 8px;
            font-size: 22px;
            width: 70%;
            @media (max-width: 767px) {
              font-size: 18px;
              width: 100%;
            }
          }
          > p {
            font-size: 13px;
          }
          .new-home-single-article-item-bottom-profile {
            border-top: 1px solid #efefef;
            margin-top: 15px;

            .profile-article-info {
              display: flex;
              justify-content: space-between;
              padding-top: 10px;
              align-items: center;
              .profile-info-left {
                display: flex;
                align-items: center;
                gap: 10px;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                }
              }
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .new-article-all-post-button-bottom {
        text-align: right;
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
        > * {
          display: flex;
          grid-gap: 8px;
          gap: 8px;
          align-items: center;
          justify-content: flex-end;
          border: none;
          background: none;
          padding: 0;
          color: #0052cc;
          font-weight: 700;
          font-size: 18px;
          border-bottom: 2px solid #0052cc;
        }
      }
    }
  }
}
