//=============================

.website-chat-application {
  position: fixed;
  width: 380px;
  height: 500px;
  right: 30px;
  bottom: 10%;
  z-index: 99;
  @media (max-width: 767px) {
    position: fixed;
    width: 310px;
    height: 450px;
    right: 6px;

    z-index: 9999;
  }
  button.website-massage-icon {
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #253642;
    // background: #0b2e4e;
    z-index: 9999999;
    bottom: 0;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
    svg {
      width: 30px;
      height: 30px;
      transform: scale(1);
      transition: 0.3s;
      @media (max-width: 767px) {
        width: 26px;
        height: 26px;
      }
    }
    span {
      visibility: hidden;

      width: 60%;
      height: 2px;
      background: #fff;
      position: absolute;
      transition: 0.3s;
      transform: rotate(0deg);
    }
    &.active {
      svg {
        transform: scale(0);
      }
      span {
        visibility: visible;

        transition: 0.7s;
        &:nth-child(2) {
          transform: rotate(-135deg) scale(1);
          background: #fff;
        }
        &:nth-child(3) {
          transform: rotate(135deg) scale(1);
          background: #fff;
        }
      }
    }
  }
  .client-chat-show-area {
    position: absolute;
    bottom: 70px;
    right: 0px;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    @media (max-width: 576px) {
      right: 10px;
    }
    .alert-popup-always-show {
      background: #fff;
      padding: 24px;

      // width: 400px;
      // @media (max-width: 576px) {
      //   width: 300px;
      // }
      &.client-part-chat {
        // position: relative !important;
        padding: 30px;

        &:after {
          content: "";
          width: 100%;
          position: absolute;
          background: #0052cc;
          height: 150px;
          top: 0;
          left: 0;
        }
        &.main-chat-part-show {
          padding: 0;
          padding-top: 20px;
          // padding-bottom: 30px;
          h6 {
            text-align: left;
            padding: 0 30px;
          }
          &:after {
            content: "";
            width: 100%;
            position: absolute;
            background: #253642;
            height: 65px;
            top: 0;
            left: 0;
          }
          .xampro-chat-body {
            padding-bottom: 40px;
            padding-left: 14px;
            padding-right: 14px;
            .admin-side-chat {
              img {
                // max-width: 120px;
                width: 130px;
                // object-fit: cover;
              }
              span {
                font-size: 10px !important;
              }
            }
            .client-side-chat {
              img {
                width: 130px;
              }
              span {
                font-size: 10px !important;
              }
            }
            @media (max-width: 767px) {
              padding-left: 10px;
              padding-right: 10px;
            }
            .xampro-single-chat {
              display: flex;
              gap: 14px;
              .xampro-chat-profile-client {
                img {
                  height: 30px;
                  @media (max-width: 767px) {
                    height: 24px;
                  }
                }
              }
              .xampro-chat-content-client {
                // border: 1px solid #efefef;
                padding: 15px;
                border-radius: 8px;
                span {
                  font-size: 10px !important;
                }
                p {
                  margin-bottom: 20px;
                  @media (max-width: 767px) {
                    margin-bottom: 10px;
                    font-size: 12px !important;
                  }
                }
                ul {
                  padding-left: 0;
                  margin-bottom: 0;
                  list-style: none;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 6px;
                  border: 1px solid rgba(217, 217, 217, 1);
                  padding: 10px;
                  li {
                    padding: 4px 10px;
                    border: 1px solid #0052cc;
                    // margin-bottom: 10px;
                    color: #0052cc;
                    border-radius: 6px;
                    cursor: pointer;
                    @media (max-width: 767px) {
                      font-size: 12px !important;
                    }
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
          .xampro-chat-write-bottom {
            position: absolute;
            left: 0;
            width: 100%;
            // height: 50px;
            bottom: 0;
            border: 1px solid #d9d9d9;
            border-radius: 2px 2px 8px 8px;
            padding: 6px;
            background: #fff;
            .chat-image {
              width: 97%;
              border-radius: 4px;
              position: absolute;
              bottom: 45px;
              background: #fff;
              padding: 10px;
              height: 70px;
              border: 1px solid #d9d9d960;
              .chat-single-image-upload-box {
                width: 50px;
                height: 50px;
                position: relative;
              }
              img {
                width: 50px;
                height: 50px;
              }
              i {
                position: absolute;
                top: -13%;
                right: -13%;
                font-size: 16px;
                color: red;
                background: #fff;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid red;
                cursor: pointer;
              }
              i.image-spin {
                color: black !important;
              }
            }
          }

          .xampro-massage-write-bottom-area {
            display: flex;
            justify-content: space-between;
            position: relative;
            height: 100%;
            align-items: center;

            .text-input-field {
              width: 100%;
              padding-left: 10px;
              input {
                width: 100%;
                border: none;
                background: none;
                &:focus {
                  outline: none;
                }
              }
            }
            .file-upload-button {
              * {
                border: none;
                background: none;
              }
            }
            .massage-send-button-main {
              * {
                border: none;
                background: none;
                svg {
                  path {
                    fill: #0052cc;
                    fill-opacity: 1;
                  }
                }
              }
            }
          }
        }
        h6 {
          font-size: 22px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
          color: #fff;
        }
        > * {
          position: relative !important;
          z-index: 9;
          background: none;
        }
      }

      > * {
        background: #fff;
        padding: 0 !important;
        position: unset !important;
        position: relative;
        z-index: 9;
        margin-top: 0;
        .alert-heading.h4 {
          font-size: 16px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    button.chat-submit-button-clients {
      width: 100%;
    }
  }
}
//=========================
.non-login-chat-user-form {
  background: rgba(255, 255, 255, 1);
  // border: 1px solid rgba(219, 219, 219, 1);
  .mb-3 {
    margin-bottom: 10px !important;
  }
  .non-login-user-form-header {
    padding: 30px;
    text-align: center;
    background: #253642;
    // background: #0b2e4e;
    padding-bottom: 50px;
    h6 {
      color: #fff;
    }
  }
  .non-login-user-form-body-inner {
    padding: 0 30px;
    margin-top: -30px;
    > div {
      background: #fff;
      padding: 0px 20px;
      border-radius: 6px;
      padding-top: 12px;
    }
    input {
      background: #fff;
    }
    label {
      margin-top: 0;
    }
    button {
      background: #253642;
      // background: #0b2e4e;
      width: 100%;
      // height: 34px;
      color: #fff;
      padding: 8px 10px;
      border-radius: 6px;
      margin-bottom: 30px;
    }
  }
}
