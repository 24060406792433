.settingsProfile {
  width: 110px;
  height: 110px;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-content {
  display: block !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}
