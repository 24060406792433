.about-hero-main-wrap {
  padding: 70px 0;
  h5 {
    font-size: 14px;
    margin-bottom: 18px;
    color: #00000067;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
  }
  p {
    color: #23253687;
    margin-top: 25px;
    margin-bottom: 40px;
    padding: 0 100px;
  }
  img {
    display: inline-block;
    padding-bottom: 50px;
  }
}
.our-expertise-main-area-wrap {
  background: rgba(226, 226, 248, 0.42);
  padding: 75px 0;
  .our-expertise-inner-container {
    .our-expertise-left-content {
      h3 {
        font-size: 30px;
        font-weight: 600;
        line-height: 60px;
        margin-top: 0;
      }
      p {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
}
.faq-main-area-wrap {
  padding: 70px 0;
  background: #fff;
  .faq-header-area-wrap {
    text-align: center;
    margin-bottom: 50px;
    h6 {
      margin-bottom: 10px;
      font-size: 14px;
    }
    h2 {
      margin-bottom: 20px;
      font-size: 48px;
      font-weight: 700;
    }
    p {
      color: rgba(93, 95, 109, 1);
      font-size: 16px;
    }
  }
  .faq-main-content-bottom-wrap {
    .faq-ques-area-left-wrap {
      .accordion {
        .accordion-item {
          background: #eff0ff;
          box-shadow: 0px 5px 20px rgb(206 206 206 / 20%);
          margin-bottom: 15px;
          font-size: 18px;
          border-radius: 10px !important;
          .accordion-header {
            button.accordion-button {
              border-radius: 10px 10px 10px 10px;
              background: #fff !important;
              font-size: 18px;
              min-height: 67px;
              color: #000000;
            }
          }
          .accordion-collapse.collapse {
            font-size: 18px;
            &.show {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
.trade-license-area-wrap {
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .about-hero-main-wrap {
    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
    }
    img {
      display: inline-block;
      padding-bottom: 15px;
      width: 100%;
    }
  }
  .our-expertise-right-content img {
    width: 100%;
    margin: 15px 0;
  }
  .our-expertise-main-area-wrap
    .our-expertise-inner-container
    .our-expertise-left-content
    h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    margin-top: 0;
  }
}
